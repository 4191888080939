import React, { useState, useEffect, useContext } from 'react'
import PlansCard from './PlansCard'
import { BsPersonHeart, BsRocketTakeoff } from 'react-icons/bs'
import { FaLayerGroup } from 'react-icons/fa'
import ExploreCard from './ExploreCard'
import { MdCorporateFare } from 'react-icons/md'
import AuthContext from './context/AuthContext'
// import { API_URL } from './config'
import Cookies from 'universal-cookie'
import { useHistory } from 'react-router-dom'

const ExplorePlans = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const [plans, setPlans] = useState()
	const [payGo, setPayGo] = useState()

	const { user, lowest, setIsLoading, isLoading, setIsPlansNav, setProfile } =
		useContext(AuthContext)


	const cookies = new Cookies()

	const history = useHistory()

	useEffect(() => {
		setIsPlansNav(true)

		setProfile(true)

		const handlePayGo = async () => {
			setIsLoading(true)
			const res = await fetch(`${API_URL}/finance/pay-go`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${cookies.get('vsm_authorization')}`,
				},
			})

			const data = await res.json()

		

			setPayGo(data)

			setIsLoading(false)
		}

		handlePayGo()
	}, [])

	useEffect(() => {
		if (user?.telco_subscription) {
			history.push('/plans/telco')
		}
	}, [history, user])

	// useEffect(() => {
	//   const handlePlans = async () => {
	//     const res = await fetch(
	//       `https://app.signaladoc.ng/api/v2/vsm-offshoot/mtn-mp/finance/price`,
	//       {
	//         method: "GET",
	//         headers: {
	//           "Content-Type": "application/json",
	//           Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
	//         },
	//       }
	//     );

	//     const data = await res.json();

	//     setPlans(data);

	//     console.log(data);
	//   };

	//   handlePlans();
	// }, []);

	return (
		<>
			{isLoading ? (
				<></>
			) : (
				<div className=''>
					{payGo && (
						<div className=' pt-[3rem] w-[70vw] mx-auto max-md:pb-[10rem] max-2xl:ml-[15rem] max-xl:ml-[15rem] max-md:mx-auto max-md:w-[90vw]'>
							<p className='medium text-2xl top-0 py-[1rem] fixed z-[1] bg-white w-[100vw] max-md:pl-[6rem] max-md:w-[100vw] max-md:bg-white max-md:h-[5rem] max-md:left-[0rem] max-md:top-0 max-md:pt-[2rem] max-md:z-[0]'>
								Explore Plans
							</p>
							<p className='text-xl text-center mb-[1rem] max-md:text-md'>
								Plans specially tailored for you
							</p>
							<div className=' w-[80vw] mx-auto grid grid-cols-3 justify-between max-md:grid max-md:grid-cols-1 items-center max-xl:grid-cols-2 max-xl:grid max-lg:grid-cols-1 max-lg:justify-items-center max-xl:w-[70vw]'>
								<div
									className=' max-md:mb-[2rem] mb-[2rem]'
									onClick={() => history.push('/plans/individual')}
								>
									<ExploreCard
										icon={<BsPersonHeart className=' text-primary' />}
										type={'Individual'}
										text={
											'Monitor your vital signs and keep track of important health information every day, all year long for as low as'
										}
										price={lowest}
										color={
											'bg-input_bg max-md:mr-[0rem] max-xl:mb-[2rem] max-md:mb-[0rem]'
										}
									/>
								</div>
								<div
									className=' max-md:mb-[2rem] mb-[2rem]'
									onClick={() => history.push('/plans/bundle')}
								>
									<ExploreCard
										icon={<FaLayerGroup className=' text-lemon' />}
										type={'VitalCare Bundle'}
										text={
											'Vitals checked? Now chat with a doctor! With the VitalCare Bundle, you can keep track of your health and connect with doctors'
										}
										color={
											'bg-lemon_bg max-md:mr-[0rem] max-xl:mb-[2rem] max-md:mb-[0rem]'
										}
									/>
								</div>
								<div
									className=' max-md:mb-[2rem] mb-[2rem]'
									onClick={() => history.push('/plans/corporate')}
								>
								
									<ExploreCard
										icon={<MdCorporateFare className=' text-brown' />}
										type={'Corporate'}
										text={
											'Revitalize your workplace with SignalADoc Vital Signs Monitoring: Your ultimate solution for employee well-being!'
										}
										color={
											'bg-light_brown mx-[1rem] max-md:mr-[0rem] max-xl:mb-[2rem] max-md:mb-[0rem]'
										}
									/>
								</div>
								<div
									className=' max-md:mb-[2rem] mb-[2rem]'
									onClick={() =>
										history.push('/plans/payment-summary', {
											e: payGo,
											isType: 'payGo',
										})
									}
								>
									<ExploreCard
										icon={<BsRocketTakeoff className=' text-bg_green' />}
										type={'Pay as You Go'}
										amount={payGo?.price}
										duration={'one off'}
										text={
											'Want to see how it works? Pay to take a reading just once!'
										}
										color={'bg-gray_bg mb-[2rem]'}
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	)
}

export default ExplorePlans
