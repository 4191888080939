import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/images/vsm-white.png";
import { Link } from "react-router-dom";
import { SlHome } from "react-icons/sl";
import { BiBookReader } from "react-icons/bi";
import { TfiTarget } from "react-icons/tfi";
import { RiExchangeDollarLine } from "react-icons/ri";
import { FiUser } from "react-icons/fi";
import { HiOutlineLogout } from "react-icons/hi";
import { TbCalendarTime } from "react-icons/tb";
import { MdNotificationsNone } from "react-icons/md";
import AuthContext from "../components/context/AuthContext";
import "./General.scss";
import { BsFillCreditCardFill } from "react-icons/bs";
import Modal from "./Modal";
import { useHistory, useLocation } from "react-router-dom";
import { IoIosNotificationsOutline } from "react-icons/io";
import { LuCalendarClock } from "react-icons/lu";
import { FiHelpCircle } from "react-icons/fi";
import RemindMe from "./RemindMe";
import Buttons from "./Buttons";
import { toast } from "react-hot-toast";

const UserNav = () => {
	const pathname = window.location.pathname;
	const [isModal, setIsModal] = useState(false);
	const [open, setOpen] = useState(false);
	const [first, setFirst] = useState("");
	const [last, setLast] = useState("");
	const [isName, setIsName] = useState(false);
	const [isDone, setIsDone] = useState(false);
	const [supportsPWA, setSupportsPWA] = useState(false);
	const [promptInstall, setPromptInstall] = useState(null);

	const history = useHistory();

	const platform = require("platform");

	const location = useLocation();

	const {
		user,
		logout,
		medData,
		profData,
		setIsRemind,
		isRemind,
		setIsSection,
		handleInit,
		handleProfile,
	} = useContext(AuthContext);

	// useEffect(() => {
	// 	const handler = (e) => {
	// 		e.preventDefault()
	// 		console.log('we are being triggered :D')
	// 		setSupportsPWA(true)
	// 		setPromptInstall(e)
	// 	}
	// 	window.addEventListener('beforeinstallprompt', handler)

	// 	return () => window.removeEventListener('transitionend', handler)
	// }, [])

	useEffect(() => {
		handleInit();
		handleProfile();

		if (user?.telco_subscription === null) {
			setFirst(user?.first_name);
			setLast(user?.surname);
			setIsName(true);
		} else if (!user?.updated_profile && user?.telco_subscription) {
			setFirst("");
			setLast("");
			setIsName(false);
		} else if (user?.updated_profile && user.telco_subscription) {
			setFirst(user?.first_name);
			setLast(user?.surname);
			setIsName(true);
		}
	}, []);

	const handleRoute = () => {
		history.push("/account/edit");

		setIsModal(false);
	};

	const handleRemind = () => {
		setIsRemind(true);
		setIsSection("reading");
	};

	return (
		<>
			{isRemind && (
				<RemindMe
					setIsRemind={setIsRemind}
					setIsModal={setIsModal}
					setIsDone={setIsDone}
				/>
			)}

			<div className=' text-white fixed z-[2] flex w-[100vw] h-[10vh] max-md:h-[5vh]'>
				<div className=' bg-primary h-[100vh] w-[15rem] p-[2rem] max-md:h-[5rem] max-md:w-[100vw] max-md:fixed max-md:bottom-0 max-md:p-[1rem]'>
					{isModal && (
						<Modal
							text={"Please update your profile before taking a reading."}
							btn={true}
							btnType={"primary"}
							btnText={"Update Profile"}
							btnCount={1}
							color={"text-white bg-primary rounded-md px-[2rem] medium"}
							event={() => handleRoute()}
						/>
					)}
					<div className=' '>
						<Link to='/home'>
							<img
								src={logo}
								alt=''
								className=' w-[10rem] object-cover mb-[10rem] max-md:hidden'
							/>
						</Link>
						<div className=' flex flex-col justify-between h-[60vh] max-md:h-full'>
							<ul className=' flex flex-col justify-between h-[15rem] text-xl max-md:flex-row max-md:h-full max-md:w-[90vw] max-md:mx-auto'>
								<li className={`${pathname === "/home" && " bold"}`}>
									<Link
										to={"/home"}
										className={`${
											pathname === "/home" && "bold opacity-[1]"
										} flex items-center text-white hover:text-white hover:bold transition ease-in-out duration-300 light max-md:flex-col max-md:text-sm opacity-[0.7]`}>
										<SlHome className=' mr-[0.5rem] max-md:mr-0 max-md:text-3xl max-md:mb-[0.5rem]' />{" "}
										Home
									</Link>
								</li>
								<li className=''>
									<Link
										to={"/learn"}
										className={` ${
											pathname === "/learn" && "bold opacity-[1]"
										} flex items-center text-white hover:text-white transition ease-in-out duration-300 light max-md:flex-col max-md:text-sm opacity-[0.7]`}>
										<BiBookReader className=' mr-[0.5rem] max-md:mr-0 max-md:text-3xl max-md:mb-[0.5rem]' />{" "}
										Learn
									</Link>
								</li>
								<li className=''>
									{!user?.updated_profile ? (
										<div
											onClick={() => setIsModal(true)}
											className={`${
												pathname === "/capture/" && "bold opacity-[1]"
											} flex items-center text-white hover:text-white transition ease-in-out duration-300 light max-md:flex-col max-md:text-sm opacity-[0.7] cursor-pointer`}>
											<TfiTarget className=' mr-[0.5rem] max-md:mr-0 max-md:text-3xl max-md:mb-[0.5rem]' />{" "}
											Capture
										</div>
									) : (
										<Link
											to={`/capture/?weight=${medData?.weight}&height=${
												medData?.height
											}&age=${profData?.age}&camera=${false}`}
											className={`${
												pathname === "/capture/" && "bold opacity-[1]"
											} flex items-center text-white hover:text-white transition ease-in-out duration-300 light max-md:flex-col max-md:text-sm opacity-[0.7]`}>
											<TfiTarget className=' mr-[0.5rem] max-md:mr-0 max-md:text-3xl max-md:mb-[0.5rem]' />{" "}
											Capture
										</Link>
									)}
								</li>
								<li className=''>
									<Link
										to={"/plans"}
										className={`${
											pathname === "/plans" && "bold opacity-[1]"
										} flex items-center text-white hover:text-white transition ease-in-out duration-300 light max-md:flex-col max-md:text-sm opacity-[0.7]`}>
										<BsFillCreditCardFill className=' mr-[0.5rem] max-md:mr-0 max-md:text-3xl max-md:mb-[0.5rem]' />{" "}
										Plans
									</Link>
								</li>
								<li className=''>
									<Link
										to={"/account"}
										className={`${
											pathname === "/account" && "bold opacity-[1]"
										} flex items-center text-white hover:text-white transition ease-in-out duration-300 light max-md:flex-col max-md:text-sm opacity-[0.7]`}>
										<FiUser className=' mr-[0.5rem] max-md:mr-0 max-md:text-3xl max-md:mb-[0.5rem]' />{" "}
										Account
									</Link>
								</li>
							</ul>

							<div
								className=' flex items-center cursor-pointer max-md:hidden'
								onClick={() => logout()}>
								<HiOutlineLogout className=' mr-[0.5rem] max-md:mr-0 max-md:text-3xl max-md:mb-[0.5rem] ' />{" "}
								Logout
							</div>
						</div>
					</div>
				</div>

				{location.pathname !== "/capture/" && (
					<div
						className={` ${
							open && "shadow-none"
						} } cursor-pointer fixed top-[1rem] left-[1rem] hidden max-md:flex flex-col items-center justify-center z-[100] bg-white  ${
							(location.pathname === "/home" ||
								location.pathname === "/account" ||
								location.pathname === "/capture/results") &&
							!open
								? "max-md:w-[100vw] max-md:h-[4rem] rounded-none max-md:top-[0rem] max-md:left-[0rem] max-md:items-start max-md:pl-[1rem]"
								: "max-md:top-[2.5rem] max-md:left-[2rem]"
						}`}
						onClick={() => setOpen(!open)}>
						<div
							className={` ${
								open && " rotate-45 mb-0"
							} bg-black h-[3px] w-[35px] rounded-lg mb-[0.4rem] transition-all duration-100 ease-in-out`}></div>
						<div
							className={` ${
								open &&
								" -rotate-[45deg] mb-0 absolute transition-all duration-100 ease-in-out"
							} bg-black h-[3px] w-[35px] rounded-lg mb-[0.4rem]`}></div>
						<div
							className={` ${
								open && "hidden"
							} bg-black h-[3px] w-[35px] rounded-lg transition-all duration-100 ease-in-out`}></div>
					</div>
				)}

				<div
					className={`${
						pathname === "/capture/" && "hidden"
					} bg-none text-black w-full flex justify-end text-xl p-[2rem] max-md:p-[2rem] max-md:z-[6]`}>
					<div className=' flex w-[60vw] justify-end items-center max-md:w-[20vw] max-lg:w-[40vw]'>
						{/* <TbCalendarTime className=" text-3xl max-md:text-4xl light" />
					<MdNotificationsNone className=" text-3xl max-md:text-4xl light" /> */}

						<div
							className={` ${
								open ? " opacity-100 block" : "opacity-0 hidden"
							} bg-black bg-opacity-80 h-[100vh] w-[100vw] fixed top-0 left-0 z-[100] transition-all duration-400 ease-in-out`}>
							<div
								className={` ${
									open
										? " max-md:translate-x-0 "
										: " max-md:translate-x-[60rem]"
								} h-[100vh] w-[80vw] bg-white shadow-md transition-all duration-400 ease-in-out delay-75 text-2xl pt-[5rem] pl-[2rem]`}>
								<div className='flex items-center mb-[2rem] cursor-pointer '>
									<IoIosNotificationsOutline className=' text-primary mr-[1rem] text-3xl' />
									<p className='text-lg'>Notification</p>
								</div>
								<div
									className='flex items-center mb-[2rem] cursor-pointer'
									onClick={() => handleRemind()}>
									<LuCalendarClock className=' text-primary mr-[1rem] text-3xl' />
									<p className='text-lg'>Reminder</p>
								</div>
								<div className='flex items-center mb-[2rem] cursor-pointer'>
									<FiHelpCircle className=' text-primary mr-[1rem] text-3xl' />
									<p className='text-lg'>Help</p>
								</div>
							</div>
						</div>

						<div className=' flex justify-end items-center max-md:hidden'>
							{user.photo === "default.jpg" ? (
								isName && (
									<div className=' bold bg-[#AEC5F1] rounded-md py-[0.5rem] px-[1rem] max-md:px-[0.5rem] max-md:py-[0.1rem] mr-[1rem] '>
										{user.first_name.charAt(0)}
									</div>
								)
							) : (
								<img
									src={user.photo_url}
									alt=''
									className=' w-[3rem] h-[3rem] mr-[0.5rem] rounded-full'
								/>
							)}
							<p className=' max-md:text-lg'>Hi {first}, Welcome</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default UserNav;
