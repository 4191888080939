import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Sendrequest, onMessageListener } from 'firebaseApp'

const Notification = () => {
	const [notification, setNotification] = useState({ title: '', body: '' })

	const notify = () => toast(<ToastDisplay />)

	function ToastDisplay() {
		return (
			<div>
				<p>
					<b>{notification?.title}</b>
				</p>
				<p>{notification?.body}</p>
			</div>
		)
	}

	useEffect(() => {
		Sendrequest()
		if (notification?.title) {
			notify()
		}
	}, [notification])

	onMessageListener()
		.then((payload) => {
			console.log(payload)
			setNotification({
				title: payload?.notification?.title,
				body: payload?.notification?.message,
			})
		})
		.catch((err) => console.log('failed: ', err))

	return (
		<div>
			<Toaster />
		</div>
	)
}

export default Notification
