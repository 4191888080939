import React, { useEffect, useState, useContext } from 'react'
import AuthContext from './context/AuthContext'
import PlansCard from './PlansCard'
import { BsPersonHeart } from 'react-icons/bs'
import { useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'
// import { API_URL } from './config'
import './General.scss'
import bestValue from 'assets/images/best.png'
import GoBack from './GoBack'

const BundlePlan = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const [plans, setPlans] = useState()

	const { user, setIsLoading, setIsPlansNav } = useContext(AuthContext)

	const cookies = new Cookies()

	const history = useHistory()

	useEffect(() => {
		setIsPlansNav(false)

		if (!user) {
			history.push('/login')
		}

		const handleIndividual = async () => {
			setIsLoading(true)
			const res = await fetch(
				`${API_URL}/finance/subscription/bundled-subscription/fee`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${cookies.get('vsm_authorization')}`,
					},
				}
			)

			const data = await res.json()

			

			setPlans(data)

			setIsLoading(false)
		}

		handleIndividual()
	}, [])

	const handleEvent = (e) => {
		history.push('/plans/payment-summary', {
			e,
			isType: 'bundle',
		})
	}

	return (
		<div className=' w-[60vw] mx-auto max-xl:ml-[15rem] max-md:mx-auto max-md:w-[80vw] max-md:pb-[10rem] relative z-0'>
			<p className='medium text-2xl top-0 py-[1rem] fixed z-[1] bg-white w-[100vw] max-md:pl-[6rem] max-md:w-[100vw] max-md:bg-white max-md:h-[5rem] max-md:left-[0rem] max-md:top-0 max-md:pt-[2rem] max-md:z-[0]'>
				VitalCare Bundles
			</p>

			<div className='pt-[5rem] relative -z-[1]'>
				<GoBack />

				<p className='text-xl medium  text-center mb-[2rem]'>
					Pick Your Best Plan
				</p>
				<div className=' grid grid-cols-2 justify-items-center items-center max-md:grid-cols-1 max-md:w-[70vw] max-md:mx-auto relative z-0'>
					{/* <img
					src={bestValue}
					alt=""
					className="  w-[30rem] max-xl:w-[30rem] max-md:w-[70vw] max-sm:w-[80vw] left-0"
				/> */}
					{plans?.fees.map((e) => (
						<div className=' max-md:mb-[2rem]' key={e.id}>
							<PlansCard
								icon={<BsPersonHeart className=' text-primary' />}
								type={e.period_string}
								amount={e.price}
								text={e.description}
								event={() => handleEvent(e)}
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default BundlePlan
