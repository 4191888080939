import React, { useContext, useEffect, useState } from "react";
import Buttons from "./Buttons";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "./context/AuthContext";
import Modal from "./Modal";
import { BsPersonHeart, BsRocketTakeoff } from "react-icons/bs";
import Moment from "react-moment";

const ActivePlan = ({
	icon,
	type,
	amount,
	duration,
	date,
	isButton,
	payGo,
	readings,
	payGoData,
	discount,
	loading,
	setIsModal,
	startDate,
	cancelled,
	isTelco,
	setTypeSub
}) => {
	const [modal, setModal] = useState(false);
	const [isIcon, setIsIcon] = useState();

	const history = useHistory();

	

	const { isUpdateSub, setIsUpdateSub } = useContext(AuthContext);

	const handleIsUpdate = () => {
		setIsUpdateSub(true);

		history.push("/plans/explore");
	};

	return (
		<>
			{!amount ? (
				<div></div>
			) : (
				<div className=' w-[60vw] mx-auto max-md:w-[90vw]'>
					{modal && (
						<Modal
							text={
								"Please note: Your current subscription will be terminated and you will be charged for your new subscription"
							}
							header={"Change Subscription?"}
							btnCount={2}
							btnText={"Go back"}
							btnText2={"Continue"}
							btn={true}
							btnType={
								"border border-2 border-primary text-primary w-full mr-[1rem]"
							}
							btnType2={
								"border border-2 border-primary text-white bg-primary w-full"
							}
							event={() => setModal(false)}
							otherEvent={() => handleIsUpdate()}
						/>
					)}

					<div
						className={` ${
							isButton
								? "w-[40rem] max-md:w-[20rem] max-md:mx-auto"
								: "w-[25rem] max-md:mx-auto"
						} bg-light_blue p-[1rem] flex rounded-md shadow-lg items-center max-md:w-[90vw] max-md:mx-auto justify-between`}>
						<div className=' h-[4rem] w-[4rem] rounded-full bg-white flex items-center justify-center text-3xl mr-[1rem]'>
							{icon}
						</div>
						<div className=' w-[80%]'>
							<h2 className=' mb-[0.5rem]'>{type}</h2>
							{discount && (
								<h1 className=' text-black text-lg mb-[1rem] line-through'>
									<span
										dangerouslySetInnerHTML={{
											__html: discount,
										}}></span>
								</h1>
							)}
							<h1 className=' text-primary text-4xl mb-[0.5rem] max-md:text-xl'>
								<span
									dangerouslySetInnerHTML={{
										__html: amount,
									}}></span>
								{duration && (
									<span className=' text-sm mb-[0.5rem]'>/{duration}</span>
								)}
							</h1>
							{history.location.pathname === "/plans/payment-summary" && (
								<div className=' medium'>
									<Moment format='MMM D YYYY' withTitle>
										{new Date()}
									</Moment>
								</div>
							)}
							{!isTelco && (
								<p className=' text-sm mb-[0.5rem]'>
									{isButton &&
										!payGo &&
										!type?.includes("Corporate") &&
										!cancelled &&
										"Autorenewal:"}{" "}
									{type?.includes("Corporate") ||
										(cancelled && "Subscription ends:")}{" "}
									{date}
								</p>
							)}
							{isButton && !payGo && !cancelled && !isTelco && (
								<div className=' flex max-md:flex-col items-center'>
									{/* <Link to={"/plans/explore"}> */}
									<Buttons
										text={"Change subscription"}
										px={"px-[1rem]"}
										color={"text-white"}
										bg={"bg-primary"}
										event={() => setModal(true)}
									/>
									{/* </Link> */}
									{!type?.includes("Corporate") && (
										<Buttons
											text={"Cancel renewal"}
											px={"px-[1rem] text-sm max-md:mt-[0.5rem]"}
											color={"text-text_gray"}
											bg={"none"}
											event={() => {
												setIsModal(true);
												setTypeSub(type);
											
											}}
										/>
									)}
								</div>
							)}

							{isButton && payGo && !cancelled && (
								<Link to='/capture'>
									<Buttons
										text={"Take a reading"}
										px={"px-[1rem]"}
										color={"text-white"}
										bg={"bg-primary"}
									/>
								</Link>
							)}

							{cancelled && (
								<p className=''>
									You have cancelled your subscription. Your subscription will
									no longer be renewed.
								</p>
							)}

							{readings === 0 && !cancelled && (
								<div className=' flex max-md:flex-col items-center'>
									<Link to='/plans/explore'>
										<Buttons
											text={"Upgrade my plan"}
											px={"px-[1rem]"}
											color={"text-white"}
											bg={"bg-primary"}
										/>
									</Link>
									<div
										className=''
										onClick={() =>
											history.push("/plans/payment-summary", {
												e: payGoData,
											})
										}>
										<Buttons
											text={"Pay one-time"}
											px={"px-[1rem]"}
											color={"text-text_gray"}
											bg={"none"}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ActivePlan;
