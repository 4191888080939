import React from "react";

const Modal = () => {
  return (
    <div className="">
      <div className=""></div>
    </div>
  );
};

export default Modal;
