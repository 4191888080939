import PaymentSummary from "components/PaymentSummary";
import React from "react";

const Payment = () => {
	return (
		<div>
			<PaymentSummary />
		</div>
	);
};

export default Payment;
