import { getSchema, Schema } from "helpers/capture";
import { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { get } from "lodash";
import { RPPGData } from "helpers/rppg";
import "./Results.scss";
import { BsInfoCircleFill } from "react-icons/bs";
import Modal from "./Modal";
import AuthContext from "components/context/AuthContext";
import { useContext } from "react";
import Cookies from "universal-cookie";
// import { API_URL } from "components/config";
import heartResult from "assets/images/heart-hist.png";
import respRateResult from "assets/images/resp-hist.png";
import oxygenResult from "assets/images/oxygen-hist.png";
import stressResult from "assets/images/stress-hist.png";
import bpResult from "assets/images/bp-hist.png";
import "components/General.scss";
import { toast } from "react-hot-toast";
import Buttons from "components/Buttons";
import ResultTemplate from "components/resultTemplate";
import Pdf from "react-to-pdf";
// import  ReactToPdf  from 'react-to-pdf'
import { RiDownloadCloud2Line } from "react-icons/ri";
import { getMessaging } from "firebase/messaging";
import RemindMe from "components/RemindMe";

export interface ResultData {
	rppgData: RPPGData;
	isAllDataCalculated: boolean;
	threeValuesCalculated: boolean;
	type: string;
	e: any;
}

export interface AssetData {
	logo: string;
	app: string;
	website: string;
	file_name: string;
	file_path: string;
	images: any;
	user_name: string;
}

export interface Messaging {
	send: any;
}

export const Results = () => {
	const history = useHistory();
	const location = useLocation();
	const API_URL = process.env.REACT_APP_API_URL;

	// console.log(location);

	const ref = useRef<any>();

	const [data, setData] = useState<ResultData>();
	const [schema, setSchema] = useState<Schema[]>();
	const [isModal, setIsModal] = useState<boolean>(false);
	const [name, setName] = useState<string>("");
	const [note, setNote] = useState<string>("");
	const [link, setLink] = useState<string>("");
	const [isDone, setIsDone] = useState<boolean>(false);
	const [date, setDate] = useState<string>("");
	const [time, setTime] = useState<string>("");
	const [message, setMessage] = useState<string>("");
	// const [isBp, setIsBp] = useState<number>(0)
	// const [isBpm, setIsBpm] = useState<number>(0)
	// const [isOxygen, setIsOxygen] = useState<number>(0)
	// const [isRr, setIsRr] = useState<number>(0)
	// const [isStress, setIsStress] = useState<number>(0)
	// const [isCalculated, setIsCalculated] = useState<number>(0)
	// const [timeZone, setTimeZone] = useState<string>("");
	const [isSuccess, setIsSuccess] = useState<boolean>(false);
	const [assets, setAssets] = useState<AssetData>();
	const [historyId, setHistoryId] = useState<number>();
	const [isPdf, setIsPdf] = useState<boolean>(false);
	const [pdfData, setPdfData] = useState<any>();
	const [histDate, setHistDate] = useState<any>();
	const [logo, setLogo] = useState<string>("");
	const [images, setImages] = useState<any>();

	const {
		setResultReading,
		resultReading,
		user,
		timeZone,
		setIsLoading,
		handleInit,
		setRemindData,
		isSection,
		setIsSection,
		isRemind,
		setIsRemind,
	} = useContext(AuthContext);

	// console.log(data);

	// console.log(resultReading);

	const cookies = new Cookies();

	// interface LocationState {

	// }

	useEffect(() => {
		handleInit();
		const histData = location.state as ResultData;

		// generatePdf()
		// console.log('ffffffffffsdfgh',histData);
		// console.log('ffffffffff',histData?.e);

		const handleAssets = async (e) => {
			setIsLoading(true);

			

			const res = await fetch(
				`${API_URL}/vital-sign/asset?id=${
					histData?.type === "history" ? e?.e?.id : e?.vitals?.id
				}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
						"Access-Control-Allow-Origin": "http://localhost:3000",
					},
				},
			);

			const assetData = await res.json();

			if (res.ok) {
				setAssets(assetData);

				setLogo(assetData.logo);
				setImages(assetData.images);
			}

			// console.log(assetData);

			setIsLoading(false);
		};

		handleInit();

		// console.log(histData.e)

		if (!histData) {
			history.push("/capture");
			return;
		}

		setSchema(getSchema());
		setData(histData);

		// console.log(data);

		const handleResult = async () => {
			if (histData?.type === "capture") {
				// console.log(histData?.rppgData.measurementData.bpm);
				setIsLoading(true);
				const res = await fetch(`${API_URL}/vital-sign`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
					},
					body: JSON.stringify({
						systolic: histData?.rppgData.bloodPressure.systolic,
						diastolic: histData?.rppgData.bloodPressure.diastolic,
						bpm: histData?.rppgData.measurementData.bpm,
						rr: histData?.rppgData.measurementData.rr,
						oxygen: histData?.rppgData.measurementData.oxygen,
						stressStatus: histData?.rppgData.measurementData.stressStatus,
						bloodPressureStatus:
							histData?.rppgData.measurementData.bloodPressureStatus,
					}),
				});

				const resData = await res.json();

				setResultReading(resData);

				// console.log(resData);

				if (histData?.type === "capture") {
					handleAssets(resData);
					setPdfData(resData?.vitals);
					setHistDate(resData?.vitals?.date_time);
					// console.log(resData?.vitals?.id);
					setHistoryId(resData?.e?.id);

					// console.log(historyId);
				}

				setIsLoading(false);

				const unlisten = history.listen(() => {
					window.location.reload();
				});
				return () => {
					unlisten();
				};
			}
		};

		handleResult();

		if (histData?.type === "history") {
			setPdfData(histData?.e);
			setHistoryId(histData?.e?.id);
			setHistDate(histData?.e?.date_time);

			handleAssets(histData);

			// console.log(data);

			// console.log(historyId);
		}
	}, [history, location.state, setAssets, data]);

	const onBackClickButtonHandler = () => {
		history.push("/capture");
	};

	const handlePush = () => {
		history.push("/home");
	};

	const handleModal = (name: string, description: string, read: string) => {
		setIsModal(true);

		setName(name);
		setNote(description);
		setLink(read);
	};

	const handleDone = () => {
		setIsDone(!isDone);
	};

	const options = {
		orientation: "portrait",
		unit: "in",
		format: [10, 12],
	};

	const handlePdf = (toPdf) => {
		toPdf();
		setIsPdf(true);

		setTimeout(() => {
			setIsPdf(false);
		}, 0.1);
	};

	const handleRemind = () => {
		setIsRemind(true);
		setIsDone(false);
		setIsSection("doctor-chat");
	};

	// const generatePDF = () => {
	// 	const rep = new jsPDF("p", "pt", "a4");
	// 	rep.html(document.querySelector("#rep1"), {
	// 		callback: function (pdf) {
	// 			pdf.save("report.pdf");
	// 		}
	// 	});
	// };

	// const generatePdf = () => {
	// 	return (

	// 	)
	// }

	return (
		<div className='results-container'>
			<div className='download text-4xl '>
				<Pdf
					targetRef={ref}
					filename={"VSM Reading" + " " + assets?.user_name + " " + histDate}
					options={options}
					x={1}
					y={0.5}
					scale={1}>
					{({ toPdf }: any) => (
						<div className=' cursor-pointer ' onClick={() => handlePdf(toPdf)}>
							<RiDownloadCloud2Line />
						</div>
					)}
				</Pdf>
			</div>
			{/* <button onClick={generatePDF}>Download</button> */}

			<div
				className={` ${isPdf ? "block" : "hidden"}`}
				// style={{ visibility: 'hidden', height: 0 }}
				ref={ref}
				id='item'>
				<ResultTemplate
					data={assets}
					vitals={pdfData}
					// images={images}
					// logo={logo}
				/>
			</div>

			{/* {generatePdf()} */}

			<div className=' flex items-start w-full'>
				<h1 className=' text-left mb-[1rem]'>
					Hi {user?.first_name}, your results
				</h1>
			</div>
			<div className='msg-block'>
				<div className='flex max-md:flex-col'>
					<p className=' mr-[3rem]'>Vital Signs</p>
					<p className=' ml-[3rem] max-md:ml-[0rem]'>
						{data?.type === "history"
							? data?.e?.date_time
							: resultReading?.vitals?.date_time}
					</p>
				</div>
			</div>

			<div className=' mb-[2rem] relative'>
				<p className=' flex text-sm'>
					For more info, click on &nbsp;{" "}
					<BsInfoCircleFill
						color='#AEC5F1'
						fontSize={30}
						className=' text-[#AEC5F1] text-xl'
					/>{" "}
					&nbsp; on each result.
				</p>
			</div>

			{isModal && (
				<div
					className=' modal fixed h-[100vh] w-[100vw] left-0 top-0 grid 
items-center z-[5] '>
					<div className=' modal-card bg-white p-[1rem] w-[20rem] mx-auto'>
						<h1 className=' text-xl mb-[1rem]'>{name}</h1>
						<p className=' text-sm'>{note}</p>

						<div
							className=' text-primary mt-[1rem] flex justify-between 
items-center text-sm'>
							<p
								className=' cursor-pointer'
								onClick={() => setIsModal(!isModal)}>
								Close
							</p>
							<a href={link}>Read More</a>
						</div>
					</div>
				</div>
			)}

			{isSuccess && <Modal />}

			{isRemind && (
				<RemindMe
					setIsRemind={setIsRemind}
					setIsModal={setIsModal}
					setIsDone={setIsDone}
				/>
			)}

			{isDone && (
				<div
					className=' modal  fixed h-[100vh] w-[100vw] left-0 top-0 grid 
items-center z-[5]'>
					<div
						className=' rounded-md bg-white py-[1rem] px-[1rem] w-[30rem] 
mx-auto max-md:w-[90%]'>
						<p className=' text-lg font-medium text-center'>
							Hey {user?.first_name}! <br />
							How did your assessment go? <br />
							Would you like to chat with a doctor?
						</p>

						<div
							className='  text-primary mt-[1rem] flex justify-between 
items-center text-sm w-[80%] mx-auto font-bold max-md:w-[90%] 
max-md:flex-col'>
							<Link to='/capture/results/doctor'>
								<button
									className=' bg-primary text-white border-2 
border-primary rounded-md py-[0.5rem] px-[1rem] max-md:mb-
[1rem] medium'>
									Yes Please
								</button>
							</Link>

							<button
								className=' text-primary border-2 border-primary rounded-md 
py-[0.5rem] px-[1rem] max-md:mb-[1rem] medium'
								onClick={() => handleRemind()}>
								Remind me later
							</button>

							<p
								className=' cursor-pointer medium'
								onClick={() => handlePush()}>
								No
							</p>
						</div>
					</div>
				</div>
			)}

			{/* {data && schema && (
        <div className="results grid grid-cols-2">
          {schema?.map((item) => (
            <div className="item" key={item.key}>
              <div className="title">
                <div className="icon">
                  <img src={item.iconResult} alt="icon-result" className=" " />
                </div>
              </div>

              <div className=" text-center">
                <div className="name">{item.name}</div>

                {get(data.rppgData, item.key, 0) ? (
                  <div className="value">
                    {get(data.rppgData, item.key, 0)}
                    <span className="sign">{item.sign}</span>
                  </div>
                ) : (
                  <div className="no-value"></div>
                )}
              </div>

              <BsInfoCircleFill
                color="#AEC5F1"
                fontSize={30}
                className=" text-[#AEC5F1] text-xl cursor-pointer"
                onClick={() => handleModal(item)}
              />
            </div>
          ))}
          {!data.isAllDataCalculated && (
            <div className="notification">
              <img
                src={require("assets/images/note-icon.svg").default}
                alt="icon-note"
              />
              One or more vitals were unable to be calculated due to bad
              lighting conditions, please try again later
            </div>
          )}
        </div>
      )} */}

			<div className='results grid grid-cols-2 max-md:grid-cols-1'>
				<div className='item'>
					<div className='title'>
						<div
							className={` ${
								data?.type === "capture" &&
								resultReading?.vitals?.blood_pressure_indicator === "warning" &&
								"warning"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.blood_pressure_indicator === "danger" &&
								"danger"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.blood_pressure_indicator === "ok" &&
								"normal"
							} ${
								data?.type === "history" &&
								data?.e?.blood_pressure_indicator === "ok" &&
								"normal"
							} ${
								data?.type === "history" &&
								data?.e?.blood_pressure_indicator === "danger" &&
								"danger"
							} ${
								data?.type === "history" &&
								data?.e?.blood_pressure_indicator === "warning" &&
								"warning"
							} icon max-md:w-[2rem]`}>
							<img
								src={bpResult}
								alt='icon-result'
								className='  max-md:w-[2rem]'
							/>
						</div>
					</div>

					<div className=' text-center'>
						<div className='name'>Blood Pressure</div>
						<div
							className={` ${
								data?.type === "capture" &&
								resultReading?.vitals?.blood_pressure_indicator === "warning" &&
								"warn-text"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.blood_pressure_indicator === "danger" &&
								"high-text"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.blood_pressure_indicator === "ok" &&
								"normal-text"
							} ${
								data?.type === "history" &&
								data?.e?.blood_pressure_indicator === "ok" &&
								"normal-text"
							} ${
								data?.type === "history" &&
								data?.e?.blood_pressure_indicator === "danger" &&
								"high-text"
							} ${
								data?.type === "history" &&
								data?.e?.blood_pressure_indicator === "warning" &&
								"warn-text"
							} value regular`}>
							{data?.type === "history"
								? data?.e?.blood_pressure_display
								: resultReading?.vitals?.blood_pressure_display}
						</div>
					</div>

					<BsInfoCircleFill
						color='#AEC5F1'
						fontSize={30}
						className=' text-[#AEC5F1] text-xl cursor-pointer'
						onClick={() =>
							handleModal(
								"Blood Pressure",
								"The pressure of circulating blood on the walls of blood vessels.",
								"https://signaladoc.com/vitalsignsmonitoring/blood-pressure",
							)
						}
					/>
				</div>
				<div className='item'>
					<div className='title'>
						<div
							className={` ${
								data?.type === "capture" &&
								resultReading?.vitals?.beats_per_minute_indicator ===
									"warning" &&
								"warning"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.beats_per_minute_indicator ===
									"danger" &&
								"danger"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.beats_per_minute_indicator === "ok" &&
								"normal"
							} ${
								data?.type === "history" &&
								data?.e.beats_per_minute_indicator === "ok" &&
								"normal"
							} ${
								data?.type === "history" &&
								data?.e?.beats_per_minute_indicator === "danger" &&
								"danger"
							} ${
								data?.type === "history" &&
								data?.e?.beats_per_minute_indicator === "warning" &&
								"warning"
							} icon`}>
							<img src={heartResult} alt='icon-result' className=' ' />
						</div>
					</div>

					<div className=' text-center'>
						<div className='name'>Heart Rate</div>
						<div
							className={` ${
								data?.type === "capture" &&
								resultReading?.vitals?.beats_per_minute_indicator ===
									"warning" &&
								"warn-text"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.beats_per_minute_indicator ===
									"danger" &&
								"high-text"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.beats_per_minute_indicator === "ok" &&
								"normal-text"
							} ${
								data?.type === "history" &&
								data?.e?.beats_per_minute_indicator === "ok" &&
								"normal-text"
							} ${
								data?.type === "history" &&
								data?.e?.beats_per_minute_indicator === "danger" &&
								"high-text"
							} ${
								data?.type === "history" &&
								data?.e?.beats_per_minute_indicator === "warning" &&
								"warn-text"
							} value regular`}>
							{data?.type === "history"
								? data?.e?.beats_per_minute_display
								: resultReading?.vitals?.beats_per_minute_display}
						</div>
					</div>

					<BsInfoCircleFill
						color='#AEC5F1'
						fontSize={30}
						className=' text-[#AEC5F1] text-xl cursor-pointer'
						onClick={() =>
							handleModal(
								"Heart Rate",
								"Also known as pulse, this the number of times a person’s heart beats per minute.",
								"https://signaladoc.com/vitalsignsmonitoring/heart-rate",
							)
						}
					/>
				</div>

				<div className='item'>
					<div className='title'>
						<div
							className={` ${
								data?.type === "capture" &&
								resultReading?.vitals?.stress_status_indicator === "warning" &&
								"warning"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.stress_status_indicator === "danger" &&
								"danger"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.stress_status_indicator === "ok" &&
								"normal"
							} ${
								data?.type === "history" &&
								data?.e?.stress_status_indicator === "ok" &&
								"normal"
							} ${
								data?.type === "history" &&
								data?.e?.stress_status_indicator === "danger" &&
								"danger"
							} ${
								data?.type === "history" &&
								data?.e?.stress_status_indicator === "warning" &&
								"warning"
							} icon`}>
							<img src={stressResult} alt='icon-result' className=' ' />
						</div>
					</div>

					<div className=' text-center'>
						<div className='name'>Stress Level</div>
						<div
							className={` ${
								data?.type === "capture" &&
								resultReading?.vitals?.stress_status_indicator === "warning" &&
								"warn-text"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.stress_status_indicator === "danger" &&
								"high-text"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.stress_status_indicator === "ok" &&
								"normal-text"
							} ${
								data?.type === "history" &&
								data?.e?.stress_status_indicator === "ok" &&
								"normal-text"
							} ${
								data?.type === "history" &&
								data?.e?.stress_status_indicator === "danger" &&
								"high-text"
							} ${
								data?.type === "history" &&
								data?.e?.stress_status_indicator === "warning" &&
								"warn-text"
							} value regular`}>
							{data?.type === "history"
								? data?.e?.stress_status_display
								: resultReading?.vitals?.stress_status_display}
						</div>
					</div>

					<BsInfoCircleFill
						color='#AEC5F1'
						fontSize={30}
						className=' text-[#AEC5F1] text-xl cursor-pointer'
						onClick={() =>
							handleModal(
								"Stress Level",
								"Based on Baevsky’s and US/European Index level measurements.",
								"https://signaladoc.com/vitalsignsmonitoring/stress",
							)
						}
					/>
				</div>

				<div className='item'>
					<div className='title'>
						<div
							className={` ${
								data?.type === "capture" &&
								resultReading?.vitals?.oxygen_indicator === "warning" &&
								"warning"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.oxygen_indicator === "danger" &&
								"danger"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.oxygen_indicator === "ok" &&
								"normal"
							} ${
								data?.type === "history" &&
								data?.e?.oxygen_indicator === "ok" &&
								"normal"
							} ${
								data?.type === "history" &&
								data?.e?.oxygen_indicator === "danger" &&
								"danger"
							} ${
								data?.type === "history" &&
								data?.e?.oxygen_indicator === "warning" &&
								"warning"
							} icon`}>
							<img src={oxygenResult} alt='icon-result' className=' ' />
						</div>
					</div>

					<div className=' text-center'>
						<div className='name'>Oxygen Saturation</div>
						<div
							className={` ${
								data?.type === "capture" &&
								resultReading?.vitals?.oxygen_indicator === "warning" &&
								"warn-text"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.oxygen_indicator === "danger" &&
								"high-text"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.oxygen_indicator === "ok" &&
								"normal-text"
							} ${
								data?.type === "history" &&
								data?.e?.oxygen_indicator === "ok" &&
								"normal-text"
							} ${
								data?.type === "history" &&
								data?.e?.oxygen_indicator === "danger" &&
								"high-text"
							} ${
								data?.type === "history" &&
								data?.e?.oxygen_indicator === "warning" &&
								"warn-text"
							} value regular`}>
							{data?.type === "history"
								? data?.e?.oxygen_display
								: resultReading?.vitals?.oxygen_display}
						</div>
					</div>

					<BsInfoCircleFill
						color='#AEC5F1'
						fontSize={30}
						className=' text-[#AEC5F1] text-xl cursor-pointer'
						onClick={() =>
							handleModal(
								"Oxygen Saturation",
								"The percentage of oxyhemoglobin (oxygen bound hemoglobin) in the blood.",
								"https://signaladoc.com/vitalsignsmonitoring/oxygen-saturation",
							)
						}
					/>
				</div>
				<div className='item'>
					<div className='title'>
						<div
							className={` ${
								data?.type === "capture" &&
								resultReading?.vitals?.respiration_rate_indicator ===
									"warning" &&
								"warning"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.respiration_rate_indicator ===
									"danger" &&
								"danger"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.respiration_rate_indicator === "ok" &&
								"normal"
							} ${
								data?.type === "history" &&
								data?.e?.respiration_rate_indicator === "ok" &&
								"normal"
							} ${
								data?.type === "history" &&
								data?.e?.respiration_rate_indicator === "danger" &&
								"danger"
							} ${
								data?.type === "history" &&
								data?.e?.respiration_rate_indicator === "warning" &&
								"warning"
							} icon`}>
							<img src={respRateResult} alt='icon-result' className=' ' />
						</div>
					</div>

					<div className=' text-center'>
						<div className='name'>Respiratory Rate</div>
						{/* {console.log(resultReading)} */}
						<div
							className={` ${
								data?.type === "capture" &&
								resultReading?.vitals?.respiration_rate_indicator ===
									"warning" &&
								"warn-text"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.respiration_rate_indicator ===
									"danger" &&
								"high-text"
							} ${
								data?.type === "capture" &&
								resultReading?.vitals?.respiration_rate_indicator === "ok" &&
								"normal-text"
							} ${
								data?.type === "history" &&
								data?.e?.respiration_rate_indicator === "ok" &&
								"normal-text"
							} ${
								data?.type === "history" &&
								data?.e?.respiration_rate_indicator === "history" &&
								"high-text"
							} ${
								data?.type === "history" &&
								data?.e?.respiration_rate_indicator === "warning" &&
								"warn-text"
							} value regular`}>
							{data?.type === "history"
								? data?.e?.respiration_rate_display
								: resultReading?.vitals?.respiration_rate_display}
						</div>
					</div>

					<BsInfoCircleFill
						color='#AEC5F1'
						fontSize={30}
						className=' text-[#AEC5F1] text-xl cursor-pointer'
						onClick={() =>
							handleModal(
								"Respiratory Rate",
								"A person’s respiratory rate is the number of breaths they take per minute.",
								"https://signaladoc.com/vitalsignsmonitoring/respiratory-rate",
							)
						}
					/>
				</div>
			</div>

			<button
				className=' bg-primary text-white py-[0.5rem] px-[5rem] rounded-md'
				onClick={() => handleDone()}>
				Done
			</button>

			{/* <Button
        primary
        onClick={onBackClickButtonHandler}
        content="Done"
      /> */}
		</div>
	);
};
