import React, { useEffect } from "react";
import Profile from "components/Profile";

const Account = () => {
  return (
    <div>
      <Profile />
    </div>
  );
};

export default Account;
