import Transaction from "components/Transaction";
import React from "react";

const SubHistory = () => {
  return (
    <div>
      <Transaction />
    </div>
  );
};

export default SubHistory;
