import ActivePlan from "components/ActivePlan";
import NoPlans from "components/NoPlans";
import React, { useEffect, useContext, useState } from "react";
import AuthContext from "components/context/AuthContext";
import { useHistory } from "react-router-dom";
import LoaderComponent from "components/LoaderComponent";
import CancelSub from "components/CancelSub";
import TelcoSub from "components/TelcoSub";

const Plans = () => {
	const {
		user,
		isLoading,
		setIsPlansNav,
		setProfile,
		activeSub,
		isIcon,
		telcoSub,
		setTelcoSub,
		canCapture,
	} = useContext(AuthContext);
	const [isModal, setIsModal] = useState(false);
	const [amount, setAmount] = useState();
	const [typeSub, setTypeSub] = useState();

	const history = useHistory();

	

	useEffect(() => {
		if (activeSub?.user_plan?.data?.is_telco) {
			setAmount(activeSub?.user_plan?.data?.amount_formatted);
		} else {
			if (activeSub?.user_plan?.data?.fee) {
				setAmount(activeSub?.user_plan.data.fee.amount_formatted);
			} else {
				setAmount(activeSub?.lowest_individual);
			}
		}

		if (user?.telco_subscription !== null && !canCapture) {
			setTelcoSub(true);
		}

		setProfile(true);
		setIsPlansNav(true);

		if (!user) {
			history.push("/login");
		}
	}, []);

	return (
		<div className=' w-[70vw] mx-auto max-md:w-[90vw] mt-[2rem]'>
			{/* {telcoSub && <TelcoSub />} */}
			{isModal && (
				<CancelSub
					typeSub={typeSub}
					setIsModal={setIsModal}
					subscription={activeSub?.user_plan.data.id}
					expiry={activeSub?.user_plan.data.ended_at}
				/>
			)}
			<p className='medium fixed text-2xl z-[4] top-[2rem] max-md:pl-[6rem] max-md:w-[100vw] max-md:bg-white max-md:h-[5rem] max-md:left-[0rem] max-md:top-0 max-md:pt-[2rem] w-[70vw] mx-auto left-[15rem] max-md:z-[0]'>
				My Plan
			</p>
			{isLoading ? (
				<LoaderComponent />
			) : user?.active_subscription || user?.unspent_prepay > 0 ? (
				<div className=' max-md:grid max-md:justify-items-center max-md:w-[90vw] max-md:mx-auto'>
					<ActivePlan
						icon={isIcon}
						type={activeSub?.user_plan?.label}
						duration={activeSub?.user_plan?.data?.period_string}
						amount={amount}
						date={
							activeSub?.user_plan?.data?.auto_renewal_string
								? activeSub?.user_plan.data.auto_renewal_string
								: `You have ${activeSub?.user_plan?.data?.readings_left} reading attempt`
						}
						isButton={true}
						payGo={
							activeSub?.user_plan?.type === "vsm-pay-as-you-go" ? true : false
						}
						loading={isLoading}
						setIsModal={setIsModal}
						setTypeSub = {setTypeSub}
						cancelled={activeSub?.user_plan?.data?.cancelled}
						isTelco={activeSub?.user_plan?.data?.is_telco}
					/>
				</div>
			) : (
				<NoPlans />
			)}
		</div>
	);
};

export default Plans;
