import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
// import { API_URL } from './config'
import Cookies from 'universal-cookie'
import PlansCard from './PlansCard'
import { BsPersonHeart } from 'react-icons/bs'
import GoBack from './GoBack'
import AuthContext from './context/AuthContext'

const Bank = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const [plans, setPlans] = useState()

	const { setIsPlansNav, setIsLoading } = useContext(AuthContext)

	const cookies = new Cookies()

	let { slug } = useParams()

	const history = useHistory()

	console.log(slug)

	useEffect(() => {
		setIsPlansNav(false)

		const handlePlans = async () => {
			setIsLoading(true)
			const res = await fetch(
				`${API_URL}/finance/discount/bank/plan?bank_id=${slug}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${cookies.get('vsm_authorization')}`,
					},
				}
			)

			const data = await res.json()

			

			setPlans(data.plans)

			setIsLoading(false)
		}

		handlePlans()
	}, [])

	const handleEvent = (e) => {
		history.push('/plans/payment-summary', {
			e,
			isType: 'individual',
			isDiscount: true,
		})
	}

	return (
		<div className=' w-[60vw] mx-auto mt-[] max-xl:ml-[15rem] max-md:mx-auto max-md:w-[80vw] max-md:pb-[10rem] z-0 relative'>
			<p className='medium top-0 py-[1rem] fixed z-[1] bg-white w-[100vw] text-2xl left-[15rem] max-md:left-[0rem] max-md:pl-[6rem] max-md:z-[0] max-md:bg-white max-md:w-[100vw] max-md:h-[4rem]'>
				Discount on Bank Cards
			</p>

			<div className=' pt-[5rem] relative -z-[1]'>
				<div className=' relative'>
					<GoBack />
				</div>

				<p className='text-xl medium text-center mb-[2rem]'>
					Pick Your Best Plan
				</p>
				<div className=' grid grid-cols-2 justify-items-center items-center max-md:grid-cols-1 max-md:w-[70vw] max-md:mx-auto'>
					{plans?.toReversed().map((e) => (
						<div className=' max-md:mb-[2rem]' key={e.id}>
							<PlansCard
								icon={<BsPersonHeart className=' text-primary' />}
								type={e.period_string}
								amount={e.discount_amount_formatted}
								text={e.description}
								event={() => handleEvent(e)}
								discount={e.plan.price}
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default Bank
