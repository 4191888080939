import React, { useState, useEffect, useContext } from 'react'
import PhoneInput from 'react-phone-number-input'
import AuthContext from './context/AuthContext'
// import { API_URL } from './config'
import Cookies from 'universal-cookie'
import toast from 'react-hot-toast'
import './General.scss'

const EmailModal = ({
	firstName,
	setFirstName,
	lastName,
	setLastName,
	email,
	setEmail,
	phoneNumber,
	setPhoneNumber,
	setIsMissing,
}) => {

	const API_URL = process.env.REACT_APP_API_URL;
	const { user, currentCntry, setLoading } = useContext(AuthContext)

	const cookies = new Cookies()

	// const handleValidate = async (e) => {
		// e.preventDefault()
// 
		// setLoading(true)
// 
		// const res = await fetch(`${API_URL}/user/profile/update-missing`, {
			// method: 'PUT',
			// headers: {
				// 'Content-Type': 'application/json',
				// Authorization: `Bearer ${cookies.get('vsm_authorization')}`,
			// },
			// body: JSON.stringify({
				// first_name: firstName,
				// surname: lastName,
				// email,
				// phone_number: phoneNumber,
			// }),
		// })
// 
		// const data = await res.json()
// 
		// if (data?.first_name && data?.surname) {
			// toast.error('First name and surname fields are required!', {
				// duration: 6000,
			// })
		// } else if (data?.first_name) {
			// toast.error(data?.first_name[0], {
				// duration: 6000,
			// })
		// } else if (data.surname) {
			// toast.error(data.surname[0], {
				// duration: 6000,
			// })
		// } else if (data.phone_number) {
			// toast.error(data.phone_number[0], {
				// duration: 6000,
			// })
		// } else if (data.email) {
			// toast.error(data.email[0], {
				// duration: 6000,
			// })
		// } else {
			// if (email || phoneNumber) {
				// toast.success(
					// 'A link has been sent to you, please verify your email/phone number',
					// {
						// duration: 6000,
					// }
				// )
			// } else {
				// toast.success(data.message, {
					// duration: 6000,
				// })
			// }
// 
			// setIsMissing(false)
		// }
// 
		// setLoading(false)
	// }



		const handleValidate = async (e) => {
			e.preventDefault();

			setLoading(true);

			const res = await fetch(`${API_URL}/user/profile/update-missing`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
				},
				body: JSON.stringify({
					first_name: firstName,
					surname: lastName,
					email,
					phone_number: phoneNumber,
				}),
			});

			const data = await res.json();

			if (data?.first_name && data?.surname) {
				toast.error("First name and surname fields are required!", {
					duration: 6000,
				});
			} else if (data?.first_name) {
				toast.error(data?.first_name[0], {
					duration: 6000,
				});
			} else if (data.surname) {
				toast.error(data.surname[0], {
					duration: 6000,
				});
			} else if (data.phone_number) {
				toast.error(data.phone_number[0], {
					duration: 6000,
				});
			} else if (data.email) {
				toast.error(data.email[0], {
					duration: 6000,
				});
			} else {
				if (email || phoneNumber) {
					toast.success(
						"A link has been sent to you, please verify your email/phone number",
						{
							duration: 6000,
						},
					);
				} else {
					toast.success(data.message, {
						duration: 6000,
					});
				}

				setIsMissing(false);
			}

			setLoading(false);
		};

	return (
		<div className='fixed top-0 right-0 z-[10] bg-black bg-opacity-40 w-[100vw] h-[100vh] flex items-center justify-center'>
			<div className=' rounded-md bg-white w-[20rem] flex flex-col items-center p-[1rem]'>
				<form action=' grid w-[90%]' onSubmit={handleValidate}>
					<div className=' mb-[1rem]'>
						<label
							htmlFor='first_name'
							className=' text-text_gray mb-[0.5rem] text-sm'>
							First Name
						</label>
						<input
							type='first_name'
							name='first_name'
							id='first_name'
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							className=' text-lg px-[1rem] py-[0.5rem] w-full border border-input_border rounded-lg mt-[0.5rem] bg-light_blue placeholder:text-sm'
							placeholder='John'
						/>
					</div>

					<div className=' mb-[1rem]'>
						<label
							htmlFor='last_name'
							className=' text-text_gray mb-[0.5rem] text-sm'>
							Surname
						</label>
						<input
							type='last_name'
							name='last_name'
							id='last_name'
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							className=' text-lg px-[1rem] py-[0.5rem] w-full border border-input_border rounded-lg mt-[0.5rem] bg-light_blue placeholder:text-sm'
							placeholder='Doe'
						/>
					</div>

					{user?.email_verified_at === null  ? (
						<div className=' mb-[1rem]'>
							<label
								htmlFor='email'
								className=' text-text_gray mb-[0.5rem] text-sm'>
								Email address
							</label>
							<input
								type='email'
								name='email'
								id='email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								className=' text-lg px-[1rem] py-[0.5rem] w-full border border-input_border rounded-lg mt-[0.5rem] bg-light_blue placeholder:text-sm'
								placeholder='doe@email.com'
							/>
						</div>
					) : (
						<div className=''>
							<label htmlFor='phone' className=' text-text_gray text-sm'>
								Phone Number
							</label>
							<div className=' flex rounded-lg mt-[0.5rem] bg-white text-lg mb-[2rem]'>
								<PhoneInput
									defaultCountry={currentCntry}
									placeholder='+2348000000000'
									value={phoneNumber}
									onChange={setPhoneNumber}
									className=' text-lg px-[1rem] py-[0.5rem] w-full border border-input_border rounded-lg bg-light_blue placeholder:text-sm'
								/>
							</div>
						</div>
					)}
					<button className=' bg-primary text-white border border-primary py-[1rem] rounded-lg w-full'>
						Submit
					</button>
				</form>

				<button
					className=' text-gray-600 bold py-[1rem] rounded-lg w-full'
					onClick={() => setIsMissing(false)}>
					Cancel
				</button>
			</div>
		</div>
	);
}

export default EmailModal
