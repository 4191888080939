import React, { useState, useContext, useEffect } from "react";
import AuthContext from "./context/AuthContext";
import Modal from "./Modal";
import "./General.scss";
import TourModal from "./TourModal";
import PlanTour from "./PlanTour";
import CaptureTour from "./CaptureTour";
import TourSteps from "./TourSteps";

const UserTour = () => {
	const {
		isNew,
		setIsNew,
		isProfile,
		setIsProfile,
		tourPlan,
		setTourPlan,
		tourCapture,
		setTourCapture,
		startTour,
		setStartTour,
		profileComplete,
		setProfileComplete,
		planComplete,
		setPlanComplete,
		captureTour,
		setCaptureTour,
		isCapture,
		user,
		checkUserLoggedIn,
		isLoading,
		setIsLoading,
		canCapture,
		profile,
	} = useContext(AuthContext);

	useEffect(() => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 4000);
		// checkUserLoggedIn()

		if (user && !user?.updated_profile && !canCapture && !profile) {
			setIsNew(true);
		} else {
			setIsNew(false);
		}
	}, []);

	const handleNew = () => {
		setIsNew(false);
		setIsProfile(true);
	};

	const handleProfile = () => {
		setIsProfile(false);
		setTourPlan(true);
	};

	const handleTour = () => {
		setTourPlan(false);
		setTourCapture(true);
	};

	const handleCapture = () => {
		setTourCapture(false);
		setStartTour(true);
	};

	return (
		<div>
			{!isLoading && (
				<div className=''>
					{isNew && !isProfile && !tourPlan && !tourCapture && (
						<div className=''>
							<Modal
								text={
									"Welcome!  Take the next three steps to begin checking your vital signs"
								}
								btnType={"text"}
								btnCount={2}
								btnType2={"text"}
								btnText={"Skip"}
								btnText2={"Next"}
								btn={true}
								color={"text-primary medium"}
								color2={"text-primary medium"}
								event={() => setIsNew(false)}
								otherEvent={() => handleNew()}
							/>
						</div>
					)}

					{isProfile && (
						<div className=''>
							<TourModal
								text={"Complete your profile setup"}
								isFinish={false}
								btnText1={"Skip"}
								btnText2={"Next"}
								event1={() => setIsProfile(false)}
								event2={() => handleProfile()}
							/>
						</div>
					)}

					{tourPlan && (
						<PlanTour
							text={"Pick a plan that best suits you"}
							isFinish={false}
							btnText1={"Skip"}
							btnText2={"Next"}
							event1={() => setTourPlan(false)}
							event2={() => handleTour()}
						/>
					)}

					{tourCapture && (
						<CaptureTour
							text={"Start monitoring your vital signs!"}
							isFinish={true}
							btnText1={"Finish"}
							event1={() => {
								handleCapture();
							}}
							// event2={() => handleCapture()}
						/>
					)}

					{startTour && (
						<TourSteps
							setStartTour={setStartTour}
						/>
					)}

					{/* {user?.updated_profile && !} */}
				</div>
			)}
		</div>
	);
};

export default UserTour;
