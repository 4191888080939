import React from 'react'
import Buttons from './Buttons'
import bestValue from 'assets/images/best.png'

const PlansCard = ({ icon, type, amount, text, event, discount }) => {
	return (
		<div className=' relative z-[0rem]'>
			{type === 'Yearly' && (
				<div className=' absolute -top-[2rem] z-[2] max-md:z-[1]'>
					<img
						src={bestValue}
						alt=''
						className='  w-[26rem] max-xl:w-[26rem] max-md:w-[70vw] max-sm:w-[80vw] left-0'
					/>
				</div>
			)}
			<div className=' bg-white px-[1rem] py-[2rem] w-[20rem] flex justify-between rounded-md shadow-lg items-center flex-col mx-auto text-center max-xl:w-[20rem] max-md:w-[70vw] max-sm:w-[80vw] mt-[1rem]'>
				{/* <a
					href='sms:12345?body=${encodeURIComponent("ITR")}'
					id="hiddenAppLink"
				>
					Send SMS
				</a> */}

				<div className=' h-[5rem] w-[5rem] rounded-full bg-light_blue flex items-center justify-center text-3xl'>
					{icon}
				</div>
				<div className=''>
					<h2 className=' my-[1rem]'>{type}</h2>
					<h1 className=' text-black text-lg mb-[1rem] line-through'>
						<span
							dangerouslySetInnerHTML={{
								__html: discount,
							}}
						></span>
					</h1>
					<h1 className=' text-primary text-4xl mb-[1rem]'>
						<span
							dangerouslySetInnerHTML={{
								__html: amount,
							}}
						></span>
					</h1>
					<p className=' text-sm mb-[1rem] w-[90%] mx-auto'>{text}</p>
					<div className=' flex '>
						<Buttons
							text={'Choose Plan'}
							px={'px-[1rem] w-full'}
							color={'text-white'}
							bg={'bg-primary'}
							event={event}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PlansCard
