import React, { useContext, useEffect, useState } from 'react'
import AuthContext from './context/AuthContext'
import Unavailable from './Unavailable'
import image from 'assets/images/transaction.png'
import TransactRow from './TransactRow'
import Cookies from 'universal-cookie'
// import { API_URL } from './config'

const Transaction = () => {
	const { transHistory } = useContext(AuthContext)

	return (
		<div>
			<div className=' pt-[3rem] w-[70vw] mx-auto max-md:pb-[10rem] max-2xl:ml-[15rem] max-md:mx-auto max-md:w-[90vw] max-md:z-[0]'>
				<div className=''>
					<p className='medium top-0 py-[1rem] fixed z-[1] bg-white w-[100vw] text-2xl left-[15rem] max-md:left-[0rem] max-md:pl-[6rem] max-md:z-[0] max-md:bg-white max-md:w-[100vw] max-md:h-[4rem]'>
						Transactions
					</p>

					{transHistory?.length > 0 &&
						transHistory?.map((e) => (
							<div className='' key={e.id}>
								<TransactRow
									type={e.particulars}
									date={e.date}
									refe={e.reference}
									amount={e.amount}
									status={e.status}
								/>
							</div>
						))}
					{transHistory?.length === 0 && (
						<Unavailable icon={image} text={'No Transaction History'} />
					)}
				</div>
			</div>
		</div>
	)
}

export default Transaction
