import RemoveAccount from "components/RemoveAccount";
import React from "react";

const Delete = () => {
  return (
    <div>
      <RemoveAccount />
    </div>
  );
};

export default Delete;
