import Modal from "components/AccountDeletionModal";
import React, { useState } from "react";

const MainAccountDeletion = () => {
	const [email, setEmail] = useState("");
	const [requestDetails, setRequestDetails] = useState("");
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [load, setLoad] = useState(false);
	const API_URL = process.env.REACT_APP_API_URL;

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoad(true)

		try {
			// Replace the URL with your backend endpoint
			const response = await fetch(`${API_URL}/user/delete-request`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ username: email, comment: requestDetails }),
			});

			// console.log("this is the response", response);

			if (response.ok) {
				setModalIsOpen(true);
                setLoad(false)
				setEmail("");
				setRequestDetails("");
			} else {
				alert(
					"The email or phone number entered is invalid. Please enter your correct email/phone number",
				);
				setLoad(false)
			}
		} catch (error) {
			// console.error("Error submitting request:", error);
			alert(
				"There was an error submitting your request. Please try again later.",
			);
			setLoad(false)
		}
	};

	return (
		<div
			style={{
				fontFamily: "Arial, sans-serif",
				maxWidth: "800px",
				margin: "auto",
				padding: "20px",
			}}>
			<h1>Request Account Deletion</h1>
			<p>
				To request the deletion of your account and associated data from the
				SignalADoc App, please follow these steps:
			</p>

			<ol>
				<li>
					- Fill out the form below with your account information and request
					details or additional comment.
				</li>
				<li>
					- Submit the form and our team will contact you within 24 working
					hours to process your request.
				</li>
			</ol>
			<p>
				Please note that upon deletion, the following types of data will be
				deleted: personal information (such as name, address, and contact
				details), account credentials, medical records, and interaction history.
				Any data that is retained, such as transaction records and
				compliance-related data, will be kept for a period of six(6) years to
				comply with legal and regulatory requirements.
			</p>
			<form onSubmit={handleSubmit}>
				<div>
					<label
						htmlFor='email'
						style={{ display: "block", marginBottom: "8px", marginTop: "2px" }}>
						Email Address/Phone number:
					</label>
					<input
						// type='email'
						id='email'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
						style={{
							width: "100%",
							padding: "10px",
							marginBottom: "20px",
							border: "1px solid #ccc",
							borderRadius: "4px",
						}}
					/>
				</div>
				<div>
					<label
						htmlFor='request-details'
						style={{ display: "block", marginBottom: "8px" }}>
						Request Details/Additional Comment:
					</label>
					<textarea
						id='request-details'
						value={requestDetails}
						onChange={(e) => setRequestDetails(e.target.value)}
						rows='4'
						required
						style={{
							width: "100%",
							padding: "10px",
							marginBottom: "20px",
							border: "1px solid #ccc",
							borderRadius: "4px",
						}}></textarea>
				</div>

				{load ? (
					<button
					disabled
						type='submit'
						style={{
							padding: "10px 20px",
							backgroundColor: "gray",
							color: "white",
							border: "none",
							borderRadius: "4px",
							cursor: "pointer",
						}}>
						Loading...
					</button>
				) : (
					<button
						type='submit'
						style={{
							padding: "10px 20px",
							backgroundColor: "#007BFF",
							color: "white",
							border: "none",
							borderRadius: "4px",
							cursor: "pointer",
						}}>
						Submit Request
					</button>
				)}
			</form>

			<Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
				<h2>Request Received</h2>
				<p>
					Your request has been received. Our team will contact you within 24
					working hours to process the deletion of your account.
				</p>
			</Modal>
		</div>
	);
};

export default MainAccountDeletion;
