import React, { useState, useContext, useEffect } from 'react'
import Modal from './Modal'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { Toaster, toast } from 'react-hot-toast'
// import { API_URL } from './config'
import { useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'
import GoBack from './GoBack'

const Enroll = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const [modal, setModal] = useState(false)
	const [id, setId] = useState('')
	const [code, setCode] = useState()
	const [number, setNumber] = useState()
	const [message, setMessage] = useState('')
	const [list, setList] = useState()
	const [enrolData, setEndrolData] = useState()

	const history = useHistory()
	const cookies = new Cookies()

	useEffect(() => {
		const handleCompany = async () => {
			const res = await fetch(`${API_URL}/finance/company/list`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${cookies.get('vsm_authorization')}`,
				},
			})

			const data = await res.json()

			setList(data)
		}

		handleCompany()
	}, [])

	const handleOnboard = async (e) => {
		e.preventDefault()
		const toastLoading = toast.loading('Loading...')

		const res = await fetch(`${API_URL}/finance/company/verify`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${cookies.get('vsm_authorization')}`,
			},
			body: JSON.stringify({
				company_id: id,
				plan_code: code,
				enrollee_number: number,
			}),
		})

		const data = await res.json()

		if (res.ok) {
			setMessage(data.message)

			if (data.type === 'subscription') {
				history.push('/plans/payment-summary', {
					e: data?.plan,
					isType: 'company',
					enrollee_id: data?.enrollee_id,
					message: data?.message,
					plan: data.plan_ids[1].plan_id,
					fee: data?.vsm_fee,
				})
			} else {
				setModal(true)
			}
		} else {
			if (data.company_id && data.enrollee_number && data.plan_code) {
				toast.error('All field are invalid!', {
					duration: 6000,
				})
			} else if (data.company_id && data.enrollee_number) {
				toast.error('Company id & enrollee number are invalid!', {
					duration: 6000,
				})
			} else if (data.company_id && data.plan_code) {
				toast.error('Company id & plan code are invalid!', {
					duration: 6000,
				})
			} else if (data.plan_code && data.enrollee_number) {
				toast.error('Plan code and enrollee number are invalid!', {
					duration: 6000,
				})
			} else if (data.company_id) {
				toast.error(data.company_id[0], {
					duration: 6000,
				})
			} else if (data.plan_code) {
				toast.error(data.plan_code[0], {
					duration: 6000,
				})
			} else if (data.enrollee_number) {
				toast.error(data.enrollee_number[0], {
					duration: 6000,
				})
			}
		}

		toast.dismiss(toastLoading)
	}

	const handleModal = () => {
		setModal(false)

		history.push('/plans')
	}

	return (
		<div className=' w-[70vw] mx-auto max-2xl:w-[60vw] max-xl:ml-[20rem] max-lg:ml-[13rem] max-md:mx-[auto] max-md:w-[90vw] max-md:pb-[10rem]'>
			{modal && (
				<Modal
					icon={<BsFillCheckCircleFill className=' text-primary text-3xl' />}
					text={message}
					header={''}
					btn={true}
					btnType={'text'}
					btnText={'Ok'}
					btnCount={1}
					color={'text-primary medium'}
					event={() => handleModal()}
				/>
			)}

			<div className=' w-[50%] max-2xl:w-[70%] max-md:w-full'>
				<Toaster position='top-center' reverseOrder={false} />
				<p className='text-2xl medium top-0 py-[1rem] fixed z-[1] bg-white w-[100vw] max-md:bg-white max-md:h-[5rem] max-md:left-[0rem] max-md:top-0 max-md:pt-[2rem] max-md:z-[0]'>
					Activate My Plan
				</p>
				<div className=' pt-[5rem]'>
					<GoBack />

					<p className=' medium mb-[1rem]'>
						Please fill in the details below as provided by your organization.
					</p>
					<form action=' grid w-[90%]' onSubmit={handleOnboard}>
						<div className=' mb-[1rem]'>
							<label htmlFor='firstname' className=' text-text_gray'>
								Company
							</label>
							<select
								name='company'
								id='company'
								className=' border border-bluee bg-input_bg rounded-md p-[0.5rem] w-full text-lg'
								onChange={(e) => setId(e.target.value)}
								value={id}
							>
								<option value='' disabled selected hidden></option>
								{list?.companies.map((company) => (
									<option value={company.id}>
										{company.name}
										{console.log(company)}
									</option>
								))}
							</select>
						</div>
						<div className=' mb-[1rem]'>
							<label htmlFor='lastname' className=' text-text_gray'>
								Plan Code
							</label>
							<input
								type='text'
								name='code'
								id='code'
								value={code}
								onChange={(e) => setCode(e.target.value)}
								className=' text-lg px-[1rem] py-[0.5rem] w-full border border-input_border rounded-lg mt-[0.5rem] bg-light_blue'
							/>
						</div>
						<div className=' mb-[1rem]'>
							<label
								htmlFor='email'
								className=' text-text_gray flex justify-between items-center'
							>
								Enrollee Number
							</label>

							<input
								type='text'
								name='number'
								id='number'
								value={number}
								onChange={(e) => setNumber(e.target.value)}
								className='text-lg px-[1rem] py-[0.5rem] w-full border border-input_border rounded-lg mt-[0.5rem] bg-light_blue'
							/>
						</div>

						<button className=' bg-primary text-white border border-input_border py-[1rem] rounded-lg w-full mt-[2rem]'>
							Proceed
						</button>
					</form>
				</div>
			</div>
		</div>
	)
}

export default Enroll
