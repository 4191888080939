import React, { useEffect } from "react";

const RangeSlider = ({ data }) => {
	const minBMI = 0;
	const maxBMI = 100;

	let mappedValue = ((data?.bmi - minBMI) / (maxBMI - minBMI)) * 100;

	// Ensure mappedValue stays within [0, 100]
	mappedValue = Math.min(98.56, Math.max(0, mappedValue));

	useEffect(() => {
		return;
	}, [data]);
	return (
		<div>
			<div className='w-[100%] h-[10px] bg-black rounded-full flex relative'>
				<div className='bg-blue-500 h-full w-[19%]'></div>
				<div className='bg-green-500 h-full w-[7%]'></div>
				<div className='bg-yellow-500 h-full w-[6%]'></div>
				<div className='bg-red-500 h-full flex-1'></div>
				<div
					style={{ border: "2px solid gray", left: `${mappedValue}%` }}
					className={`absolute  h-[20px] w-[6px] top-[-6px] border-5 border-gray-500 bg-white`}></div>
			</div>
			
		</div>
	);
};

export default RangeSlider;
