import Bank from "components/Bank";
import AuthContext from "components/context/AuthContext";
import React, { useEffect, useContext } from "react";

const BankDiscount = () => {
	const { isPlansNav, setIsPlansNav } = useContext(AuthContext);

	useContext(() => {
		setIsPlansNav(false);
	}, []);

	return (
		<div>
			<Bank />
		</div>
	);
};

export default BankDiscount;
