import React, { useState, useContext, useEffect } from 'react'
import { toast } from 'react-hot-toast'
// import { API_URL } from './config'
import Cookies from 'universal-cookie'
import { useHistory } from 'react-router-dom'
import Buttons from './Buttons'
import AuthContext from './context/AuthContext'
import Modal from './Modal'
import './General.scss'

const RemindMe = ({ setIsRemind, setIsModal, setIsDone }) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const [date, setDate] = useState()
	const [time, setTime] = useState()
	const [message, setMessage] = useState()
	// const [timeZone, setTimeZone] = useState()
	const [section, setSection] = useState()
	const [isSuccess, setIsSuccess] = useState()
	const [remind, setRemind] = useState()
	// const [isDone, setIsDone] = useState(false)

	const { isSection, setIsSection, timeZone } = useContext(AuthContext)

	const history = useHistory()



	const cookies = new Cookies()

	const handleRemind = async () => {
		const toastLoading = toast.loading('Loading...')
		const res = await fetch(`${API_URL}/notification/create`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${cookies.get('vsm_authorization')}`,
			},
			body: JSON.stringify({
				date,
				time,
				timezone: timeZone,
				message,
				section: isSection,
			}),
		})

		const data = await res.json()


		if (res.ok) {
			// toast.success('Your reminder has been set!', {
			// 	duration: 6000,
			// })
			setRemind(data.message)

			setIsSuccess(true)
			// setRemindData(data)
			// setIsRemind(false)
			// setIsDone(true)
		} else {
			toast.error(data.message, {
				duration: 6000,
			})
		}

		toast.dismiss(toastLoading)
	}


	const handleIsRemind = () => {
		setIsModal(false)
		setIsRemind(false)
		setIsDone(false)
	}

	const handleDone = () => {
		setIsDone(true)
		setIsSuccess(false)
		setIsRemind(false)

		if (history.pathname === '/capture/results') {
			history.push('/home')
		} else {
			history.go(0)
		}
	}

	return (
		<div>
			{/* {isDone && (
        <Modal text={``} />
      )} */}
			<div
				className={` ${
					isSuccess && 'hidden z-[0]'
				} fixed top-0 right-0 z-[7] bg-black bg-opacity-70 w-[100vw] h-[100vh] flex items-center justify-center`}
			>
				<div
					className={` ${
						isSuccess && 'hidden'
					} rounded-md bg-white w-[30rem] p-[2rem] relative max-md:w-[80%]`}
				>
					<h1
						className=' absolute top-[1rem] right-[1rem] font-bold cursor-pointer text-3xl'
						onClick={() => handleIsRemind()}
					>
						x
					</h1>
					<p className='medium text-3xl mb-[1rem]'>Set Reminder</p>
					<label htmlFor='date' className=' text-sm text-text_gray mb-[0.5rem]'>
						Select Date
					</label>
					<input
						type='date'
						name='date'
						id='date'
						value={date}
						className=' border border-bluee bg-input_bg rounded-md p-[0.5rem] mb-[2rem] w-full text-lg'
						onChange={(e) => setDate(e.target.value)}
					/>
					<label htmlFor='time' className=' text-sm text-text_gray mb-[0.5rem]'>
						Select Time
					</label>
					<input
						type='time'
						name='time'
						id='time'
						value={time}
						className=' border border-bluee bg-input_bg rounded-md p-[0.5rem] mb-[2rem] w-full text-lg'
						onChange={(e) => setTime(e.target.value)}
					/>

					<label
						htmlFor='message'
						className=' text-sm text-text_gray mb-[0.5rem]'
					>
						Enter a message to yourself
					</label>
					<textarea
						name='message'
						id='message'
						cols={10}
						rows={3}
						value={message}
						className=' border border-bluee bg-input_bg rounded-md p-[0.5rem] mb-[2rem] w-full text-lg'
						onChange={(e) => setMessage(e.target.value)}
					></textarea>
					<div className={''}>
						<Buttons
							text={'Set Reminder'}
							border={'border-1 border-primary'}
							bg={'bg-primary'}
							color={'text-white'}
							px={'px-[4rem] max-md:px-[2rem]'}
							resonsive=''
							event={() => handleRemind()}
						/>
					</div>
				</div>
			</div>

			{isSuccess && (
				<div className=' z-[20]'>
					<Modal
						text={remind}
						btn={'text'}
						btnText={'Ok'}
						event={() => handleDone()}
						color={'text-primary medium z-[8]'}
					/>
				</div>
			)}
		</div>
	)
}

export default RemindMe
