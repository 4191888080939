import React, { useState, useEffect, useContext } from "react";
import AuthContext from "./context/AuthContext";
import ActivePlan from "./ActivePlan";
import { useLocation, useHistory } from "react-router-dom";
import {
	BsFillCheckCircleFill,
	BsPersonHeart,
	BsRocketTakeoff,
} from "react-icons/bs";
import {
	FlutterWaveButton,
	closePaymentModal,
	useFlutterwave,
} from "flutterwave-react-v3";
// import { PAYSTACK_PUBLIC_KEY, RAVE_KEY } from './config'
import { usePaystackPayment } from "react-paystack";
import paystack from "assets/images/paystack.png";
import flutterwave from "assets/images/flutterwave.png";
// import { API_URL } from "./config";
// import { url } from "inspector";
import Cookies from "universal-cookie";
import { toast } from "react-hot-toast";
import DiscountCheck from "./DiscountCheck";
import Modal from "./Modal";
import GoBack from "./GoBack";
import Moment from "react-moment";
import { MdCorporateFare } from "react-icons/md";
import { FaLayerGroup } from "react-icons/fa";

const PaymentSummary = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const PAYSTACK_PUBLIC_KEY = process.env.REACT_APP_PAYSTACK_KEY;
	const RAVE_KEY = process.env.REACT_APP_RAVE_KEY;

	console.log(API_URL, PAYSTACK_PUBLIC_KEY, RAVE_KEY, process.env);
	const [code, setCode] = useState("");
	const [url, setUrl] = useState("");
	const [modal, setModal] = useState(false);
	const [message, setMessage] = useState("");
	const [promoDetails, setPromoDetails] = useState();
	const [amount, setAmount] = useState();
	const [promoModal, setPromoModal] = useState(false);
	const [isIcon, setIsIcon] = useState();
	const [planId, setPlanId] = useState();
	const [section, setSection] = useState();
	const [priceId, setPriceId] = useState();

	const [price, setPrice] = useState();

	const location = useLocation();
	const history = useHistory();

	const {
		user,
		checkUserLoggedIn,
		setIsLoading,
		isUpdateSub,
		profile,
		setIsPlanComplete,
		setProfile,
		canCapture,
		handleProfile,
		handleInit,
	} = useContext(AuthContext);

	const cookies = new Cookies();

	const [details, setDetails] = useState(location?.state?.e);

	const search = useLocation().search;

	const searchParams = new URLSearchParams(search);

	useEffect(() => {
		handleDiscount();

		handleProfile();
		handleInit();

		if (location?.state?.isType === "individual") {
			setPlanId(location?.state.e.plan_ids[1]?.plan_id);
			setSection("vsm-subscription");
			setPriceId(location?.state?.e.id);
		} else if (location?.state?.isType === "payGo") {
			setPlanId("");
			setSection("vsm-payg");
			setPriceId(location?.state?.e.id);
		} else if (location?.state?.isType === "company") {
			setPlanId(location?.state?.plan.plan);
			setSection("vsm-company-subscription");
			setPriceId(location?.state?.e.id);
		} else if (location?.state?.isType === "bundle") {
			// setSection('Vitalcare bundle')
			setPriceId(location?.state?.e.id);
		}
	}, []);

	const handleDiscount = async () => {
		if (searchParams.get("reference")) {
			setIsLoading(true);
			const res = await fetch(`${API_URL}/finance/discount/bank/verify`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
				},
				body: JSON.stringify({
					reference: searchParams.get("trxref"),
				}),
			});
			const data = await res.json();

			if (res.ok) {
				setModal(true);
				setMessage(data.message);
			}

			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (!canCapture) {
			setProfile(false);
		}

		if (details?.amount) {
			setAmount(details?.amount);
		} else if (details?.discount_amount) {
			setAmount(details?.discount_amount);
		}

		if (details?.price) {
			setPrice(details?.price);
		} else {
			setPrice(details?.discount_amount_formatted);
		}

		if (details?.amount_formatted) {
			setPrice(details?.amount_formatted);
		}

		if (location.state?.isType === "individual") {
			setIsIcon(<BsPersonHeart className=' text-primary' />);
		} else if (location.state?.isType === "company") {
			setIsIcon(<MdCorporateFare className=' text-brown' />);
		} else if (location.state?.isType === "payGo") {
			setIsIcon(<BsRocketTakeoff className=' text-bg_green' />);
		} else if (location.state?.isType === "bundle") {
			setIsIcon(<FaLayerGroup className=' text-lemon' />);
		}
	}, []);

	const raveMin = 10000;
	const raveMax = 99999;

	const config = {
		public_key: RAVE_KEY,
		tx_ref: `VSM-${Math.floor(
			raveMin + Math.random() * (raveMax - raveMin),
		).toString()}-${Math.floor(Date.now() / 1000).toString()}`,
		amount: amount,
		currency: details?.currency
			? details?.currency.code
			: details?.plan.currency.code,
		payment_options: "card,mobilemoney,ussd",
		payment_plan: planId,
		// payment_plan:
		// 	location?.state.isType !== "payGo" && details?.plan_ids[1]?.plan_id,
		customer: {
			email: user?.email ? user?.email : `user${user?.id}@gmail.com`,
			phone_number: user?.phone_number,
			name: user?.name,
		},
		customizations: {
			title: "SignalADoc",
			description: "Subscription",
			logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
		},
		meta: {
			user_id: user?.id,
			section,
			period: details?.period,
			country: details?.country_code,
			// price_id: details?.id,
			promo_code: code,
			price_id: priceId,
		},
	};

	const handleFlutterPayment = useFlutterwave(config);

	const handleCompanyPayment = async (e) => {
		setIsLoading(true);
		const res = await fetch(`${API_URL}/finance/company/subscription/verify`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
			},
			body: JSON.stringify({
				reference: e.tx_ref,
				enrollee_id: location.state?.enrollee_id,
				plan_id: details?.id,
			}),
		});

		const data = await res.json();

		setIsLoading(false);

		if (res.ok) {
			setModal(true);

			setMessage(data.message);
			checkUserLoggedIn();
		}
	};

	const handleModal = () => {
		setModal(false);

		if (!profile) {
			history.push("/home");
			setProfile(false);
		} else {
			history.push("/plans");

			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	};

	const handleCompanyPaymentTwo = async (e) => {
		setIsLoading(true);
		const res = await fetch(`${API_URL}/finance/company/subscription/verify`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
			},
			body: JSON.stringify({
				reference: e.reference,
				enrollee_id: location.state?.enrollee_id,
				plan_id: details?.id,
				// tx_ref: e.tx_ref.toString(),
			}),
		});

		const data = await res.json();

		setIsLoading(false);

		if (res.ok) {
			setModal(true);

			setMessage(data.message);

			checkUserLoggedIn();
		}
	};

	const handlePayGoPayment = async (e) => {
		setIsLoading(true);
		const res = await fetch(`${API_URL}/finance/pay-go/verify`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
			},
			body: JSON.stringify({
				keyword: details?.gateways[1].keyword,
				promo_code: code,
				trx_ref: e.tx_ref,
				update_subscription: isUpdateSub,
			}),
		});

		const data = await res.json();

		setIsLoading(false);

		if (res.ok) {
			setModal(true);

			setMessage(data.message);

			checkUserLoggedIn();
		}
	};

	const handlePayGoPaymentTwo = async (e) => {
		setIsLoading(true);
		const res = await fetch(`${API_URL}/finance/pay-go/verify`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
			},
			body: JSON.stringify({
				keyword: details?.gateways[0].keyword,
				promo_code: code,
				trx_ref: e.reference,
			}),
		});

		const data = await res.json();

		setIsLoading(true);

		if (res.ok) {
			setModal(true);

			setMessage(data.message);

			checkUserLoggedIn();
		}
	};

	const handleIndividualPayment = async (e) => {
		setIsLoading(true);
		const res = await fetch(
			`${API_URL}/finance/subscription/individual/verify`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
				},
				body: JSON.stringify({
					keyword: details?.plan_ids[1].keyword,
					promo_code: code,
					fee_id: details?.id,
					trx_ref: e.tx_ref,
					update_subscription: isUpdateSub,
				}),
			},
		);

		const data = await res.json();

		setIsLoading(false);

		if (res.ok) {
			setModal(true);

			setMessage(data.message);

			checkUserLoggedIn();
		}
	};

	const handleBundlePayment = async (e) => {
		setIsLoading(true);
		const res = await fetch(
			`${API_URL}/finance/subscription/bundled-subscription/verify`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
				},
				body: JSON.stringify({
					keyword: details?.plan_ids[1].keyword,
					fee_id: details?.id,
					reference: e.tx_ref,
					update_subscription: isUpdateSub,
				}),
			},
		);

		const data = await res.json();

		setIsLoading(false);

		if (res.ok) {
			setModal(true);

			setMessage(data.message);

			checkUserLoggedIn();
		}
	};

	// console.log(
	// 	`VSM-${Math.floor(
	// 		raveMin + Math.random() * (raveMax - raveMin)
	// 	).toString()}-${Math.floor(Date.now() / 1000).toString()}`
	// );

	const handleIndividualPaymentTwo = async (e) => {
		setIsLoading(true);

		const res = await fetch(
			`${API_URL}/finance/subscription/individual/verify`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
				},
				body: JSON.stringify({
					keyword: details?.plan_ids[0]?.keyword,
					promo_code: code,
					fee_id: details?.id,
					trx_ref: e.reference,
					update_subscription: isUpdateSub,
				}),
			},
		);

		const data = await res.json();

		setIsLoading(false);

		if (res.ok) {
			setModal(true);

			setMessage(data.message);

			checkUserLoggedIn();
		}
	};

	const handleBundlePaymentTwo = async (e) => {
		setIsLoading(true);

		const res = await fetch(
			`${API_URL}/finance/subscription/bundled-subscription/verify`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
				},
				body: JSON.stringify({
					keyword: details?.plan_ids[0]?.keyword,
					fee_id: priceId,
					reference: e.reference,
					update_subscription: isUpdateSub,
				}),
			},
		);

		const data = await res.json();

		setIsLoading(false);

		if (res.ok) {
			setModal(true);

			setMessage(data.message);

			checkUserLoggedIn();
		}
	};

	const paystackConfig = {
		reference: `VSM-${Math.floor(
			raveMin + Math.random() * (raveMax - raveMin),
		).toString()}-${Math.floor(Date.now() / 1000).toString()}`,
		email: user?.email ? user?.email : `user${user?.id}@gmail.com`,
		amount: `${amount}00`, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
		publicKey: PAYSTACK_PUBLIC_KEY,
		meta: {
			section,
			price_id: priceId,
			user_id: user.id,
		},
	};

	// you can call this function anything
	const onSuccess = (reference) => {
		// Implementation for whatever you want to do with reference and after success call.
		if (location.state?.isType === "individual") {
			handleIndividualPaymentTwo(reference);
		} else if (location.state?.isType === "company") {
			handleCompanyPaymentTwo(reference);
		} else if (location.state?.isType === "payGo") {
			handlePayGoPaymentTwo(reference);
		} else if (location.state?.isType === "bundle") {
			handleBundlePaymentTwo(reference);
		}
	};

	// you can call this function anything
	const onClose = () => {
		// implementation for  whatever you want to do when the Paystack dialog closed.
	};

	const handlePromo = async () => {
		setIsLoading(true);
		const res = await fetch(`${API_URL}/promo-code/verify`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
			},
			body: JSON.stringify({
				code,
				section: "subscription",
			}),
		});

		const data = await res.json();

		setIsLoading(false);

		if (res.ok) {
			setPromoModal(true);

			setMessage(data.message);

			checkUserLoggedIn();

			data.prices.map((e) => {
				if (details?.id === e?.id) {
					setAmount(e.amount);
					setPrice(e.amount_formatted);
				}
			});

			setPromoDetails(data);
		} else {
			toast.error(data.message, {
				duration: 6000,
			});
		}
	};

	const PaystackHookExample = () => {
		const initializePayment = usePaystackPayment(paystackConfig);
		return (
			<div>
				<button
					onClick={() => {
						initializePayment(onSuccess, onClose);
					}}
					className='  flex items-center text-xl bg-[#011B33] py-[0.5rem] px-[1rem] rounded-md medium text-white justify-between w-[25rem] max-md:w-[90vw]'>
					Pay with <img src={paystack} alt='' className=' w-[10rem]' />
				</button>
			</div>
		);
	};

	return (
		<div
			className={
				" relative w-[50vw] mx-auto max-md:w-[90vw] pt-[5rem] pb-[10rem]"
			}>
			{modal && (
				<Modal
					icon={<BsFillCheckCircleFill className=' text-primary text-3xl' />}
					text={message}
					header={"Success!"}
					btn={true}
					btnType={"text"}
					btnText={"Ok"}
					btnCount={1}
					color={"text-primary medium"}
					event={() => handleModal()}
				/>
			)}
			{promoModal && (
				<Modal
					icon={<BsFillCheckCircleFill className=' text-primary text-3xl' />}
					text={message}
					header={"Success!"}
					btn={true}
					btnType={"text"}
					btnText={"Ok"}
					btnCount={1}
					color={"text-primary medium"}
					event={() => setPromoModal(false)}
				/>
			)}
			{location.state && location.state?.isDiscount ? (
				<DiscountCheck details={details} />
			) : (
				location.state && (
					<div className=' max-md:w-[90vw] max-md:mx-auto max-md:grid max-md:justify-items-center'>
						<p className='medium z-[4] top-[2rem] text-2xl max-md:z-[0] max-md:left-[2rem] max-md:relative max-md:-top-[3rem]'>
							Payment Summary
						</p>

						<div className=' max-md:justify-self-start'>
							<GoBack />
						</div>

						{location.state?.isType === "payGo" && (
							<p className='medium mb-[0.5rem]'>
								Take one reading! Check your vital signs
							</p>
						)}

						<ActivePlan
							icon={isIcon}
							type={
								location.state?.isType === "payGo"
									? "Pay as you go"
									: details?.period_string
							}
							amount={price}
							discount={promoDetails && details?.price}
							duration={
								location.state?.isType === "payGo"
									? "one off"
									: details?.period_string
							}
						/>
						{location.state?.isType !== "payGo" &&
							location.state?.isType !== "company" && (
								<div className='mt-[2rem]'>
									<label htmlFor='code'>Have a promo code?</label>

									<div className=' relative w-[25rem] mt-[0.5rem] max-md:w-[90vw] max-md:mx-auto'>
										<input
											type='text'
											value={code}
											onChange={(e) => setCode(e.target.value)}
											id='code'
											name='code'
											className=' border border-1 w-[70%] border-bluee rounded-md py-[0.5rem] px-[0.5rem] max-md:w-full'
										/>
										<button
											className=' bg-light_blue text-primary py-[0.5rem] px-[1rem] border border-1 border-bluee rounded-md absolute right-[0rem]'
											onClick={() => handlePromo()}>
											Apply
										</button>
									</div>
								</div>
							)}

						{location.state?.isType !== "company" && (
							<div className=' mt-[1rem]'>
								<PaystackHookExample />
							</div>
						)}
						<div className=' mt-[1rem]'>
							<button
								onClick={() => {
									handleFlutterPayment({
										callback: (response) => {
											if (location.state?.isType === "individual") {
												handleIndividualPayment(response);
											} else if (location.state?.isType === "company") {
												handleCompanyPayment(response);
											} else if (location.state?.isType === "payGo") {
												handlePayGoPayment(response);
											} else if (location.state?.isType === "bundle") {
												handleBundlePayment(response);
											}

											closePaymentModal(); // this will close the modal programmatically
										},
										// onClose: () => {
										//
										// },
									});
								}}
								className=' flex items-center text-xl bg-[#F5A623] py-[0.5rem] px-[1rem] rounded-md medium justify-between w-[25rem] max-md:w-[90vw]'>
								Pay with <img src={flutterwave} alt='' className=' w-[10rem]' />
							</button>
						</div>
					</div>
				)
			)}
		</div>
	);
};

export default PaymentSummary;
