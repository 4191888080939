import Corp from "components/Corp";
import React from "react";

const Corperate = () => {
	return (
		<div>
			<Corp />
		</div>
	);
};

export default Corperate;
