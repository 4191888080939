import React, { useState, useEffect, useContext } from "react";
import AuthContext from "./context/AuthContext";
// import { API_URL } from './config'
import Cookies from "universal-cookie";
import { FaThumbsUp, FaUserEdit } from "react-icons/fa";
import { BiShow, BiHide } from "react-icons/bi";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { HiOutlineLogout } from "react-icons/hi";
import { BsPersonFillX } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import Modal from "./Modal";
import { RiErrorWarningLine } from "react-icons/ri";

const Profile = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const [isNew, setIsNew] = useState(false);
	const [isOld, setIsOld] = useState(false);
	const [oldPass, setOldPass] = useState("");
	const [newPass, setNewPass] = useState("");
	const [photo, setPhoto] = useState();
	const [isModal, setIsModal] = useState(false);
	const [first, setFirst] = useState("");
	const [last, setLast] = useState("");
	const [isName, setIsName] = useState(false);
	const [isMissing, setIsMissing] = useState(false);

	const { user, profData, medData, logout, checkUserLoggedIn, handleProfile } =
		useContext(AuthContext);

	const history = useHistory();

	const cookies = new Cookies();

	useEffect(() => {
		if (!user?.missing_fields) {
			setFirst(user?.first_name);
			setLast(user?.surname);
			setIsName(true);
		} else if (!user?.updated_profile && user?.telco_subscription) {
			setFirst("");
			setLast("");
			setIsName(false);
		} else if (user?.updated_profile && user.telco_subscription) {
			setFirst(user?.first_name);
			setLast(user?.surname);
			setIsName(true);
		}

		handleProfile();

		if (!user) {
			history.push("/login");
		}
	}, []);

	const handleChange = (e) => {
		setPhoto(e.target.files[0]);

		handleUpload(e.target.files[0]);
	};

	const handleUpload = async (e) => {
		const toastLoading = toast.loading("Loading...");
		let formData = new FormData();

		formData.append("photo", e);

		const res = await fetch(`${API_URL}/user/profile/photo`, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
			},
			body: formData,
		});

		const data = await res.json();

		if (res.ok) {
			toast.success(data.message, {
				duration: 6000,
			});
		} else {
			toast.error(data.photo[0], {
				duration: 6000,
			});
		}

		toast.dismiss(toastLoading);

		checkUserLoggedIn();
	};

	const handlePasswordUpdate = async (e) => {
		e.preventDefault();

		const toastLoading = toast.loading("Loading...");

		const res = await fetch(`${API_URL}/user/profile/password`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
			},
			body: JSON.stringify({
				password: oldPass,
				new_password: newPass,
			}),
		});

		const data = await res.json();

		if (data.success) {
			// toast.success("Password changed!", {
			// 	duration: 6000,
			// });
			setIsModal(true);
		} else if (data.new_password) {
			toast.error(data.new_password[0], {
				duration: 10000,
			});
		} else {
			toast.error(data.message, {
				duration: 6000,
			});
		}

		setOldPass("");
		setNewPass("");

		toast.dismiss(toastLoading);
	};

	useEffect(() => {
		if (user?.missing_fields || !user?.updated_profile) {
			setIsMissing(true);
		}
	}, []);

	console.log("this is the user", user);

	return (
		<div className=' pt-[5rem] w-[70%] mx-auto max-md:w-[90%] max-md:pb-[8rem] max-2xl:ml-[15rem] max-md:mx-auto max-md:pt-[5rem] relative pb-[5rem]'>
			{user?.email_verified_at === null ||
			user?.phone_number_verified_at === null ? (
				<div className='flex bg-[#F5EBE1] p-3  gap-3 items-center mb-5'>
					<div className='text-primary text-[17px]'>
						<RiErrorWarningLine />
					</div>
					<div>
						Verify your phone number/email address for added security and easier
						account recovery.{" "}
						<Link to='/account/edit'>
							<span className='text-primary'>Verify</span>
						</Link>{" "}
					</div>
				</div>
			) : null}
			{isModal && (
				<Modal
					icon={<FaThumbsUp className=' text-primary text-3xl' />}
					header={"Password Updated!"}
					text={"Your password has been changed"}
					btn={true}
					btnType={"text"}
					btnText={"Ok"}
					btnCount={1}
					color={"text-primary medium"}
					event={() => setIsModal(false)}
				/>
			)}
			<h1 className='medium top-0 py-[1rem] fixed z-[1] bg-white w-[100vw] text-2xl left-[15rem] max-md:left-[0rem] max-md:pl-[6rem] max-md:z-[0] max-md:bg-white max-md:w-[100vw] max-md:h-[4rem]'>
				Account
			</h1>
			<div className=' flex items-center justify-between border-b border-b-bluee pb-[1rem] max-md:justify-start max-md:border-b-0 max-md:mt-[3rem] max-md:flex-col max-md:items-start'>
				<div className=' flex items-center'>
					{user.photo === "default.jpg" ? (
						!user?.missing_fields && (
							<div className=' bold bg-[#AEC5F1] rounded-md py-[0.5rem] px-[1rem] max-md:px-[0.5rem] h-[3rem] w-[3rem] flex justify-center items-center max-md:py-[0.1rem] mr-[1rem] '>
								{user.first_name.charAt(0)}
							</div>
						)
					) : (
						<img
							src={user.photo_url}
							alt=''
							className=' w-[4rem] h-[4rem] rounded-full max-md:mr-[1rem]'
						/>
					)}
					<input
						type='file'
						name='photo'
						id='photo'
						className=' hidden'
						onChange={(e) => handleChange(e)}
					/>
					<label
						htmlFor='photo'
						className=' text-primary cursor-pointer max-md:mr-[1rem] medium'>
						Change profile photo
					</label>
				</div>
				<div className=' w-[70%] max-md:w-full'>
					<Link
						to='/account/edit'
						className=' max-md:flex hidden max-md:items-center max-md:my-[1rem]'>
						<FaUserEdit className=' text-primary mb-[0.1rem] text-2xl max-md:mr-[0.5rem]' />
						<p className=''>Edit Profile</p>
					</Link>

					<div
						className={` flex justify-between items-center w-full border-x border-x-bluee px-[1rem] h-[5rem] max-md:w-[90%] max-md:flex-col max-md:items-start ${
							user?.email && user?.phone_number
								? "max-md:h-[15rem]"
								: "max-md:h-[12rem]"
						}  max-md:px-[0rem]  max-md:border-x-0 max-md:justify-normal max-lg:px-[1rem]`}>
						<div className=' max-md:border-b max-md:border-b-bluee max-md:w-full max-md:mb-[1rem]'>
							<p className=' text-text_gray font-light mb-[0.5rem] text-[0.7rem]'>
								First Name
							</p>
							<p className=' font-bold text-sm'>
								{!user?.missing_fields && user?.first_name}
							</p>
						</div>
						<div className=' max-md:border-b max-md:border-b-bluee max-md:w-full max-md:mb-[1rem]'>
							<p className=' text-text_gray font-light mb-[0.5rem] text-[0.7rem]'>
								Last Name
							</p>
							<p className=' font-bold text-sm'>
								{!user?.missing_fields && user?.surname}
							</p>
						</div>
						{user?.email && (
							<div className=' max-md:border-b max-md:border-b-bluee max-md:w-full max-md:mb-[1rem]'>
								<p className=' text-text_gray font-light mb-[0.5rem] text-[0.7rem]'>
									Email address
								</p>
								<p className=' font-bold text-sm'>{user?.email}</p>
							</div>
						)}
						{user?.phone_number && (
							<div className=' max-md:border-b max-md:border-b-bluee max-md:w-full max-md:mb-[1rem]'>
								<p className=' text-text_gray font-light mb-[0.5rem] text-[0.7rem]'>
									Phone Number
								</p>
								<p className=' font-bold text-sm'>{user?.phone_number}</p>
							</div>
						)}
					</div>
				</div>
				<Link to='/account/edit' className=' max-md:hidden max-lg:pl-[1rem]'>
					<FaUserEdit className=' text-primary mb-[0.1rem] text-2xl' />
					<p className=''>Edit Profile</p>
				</Link>
			</div>

			<div className=' py-[1rem] border-b border-b-bluee flex justify-between max-md:border-b-0 max-md:flex-col max-md:py-[0rem]'>
				<div className=' max-md:border-b max-md:border-b-bluee max-md:w-full max-md:mb-[1rem]'>
					<p className=' text-text_gray font-light mb-[0.5rem] text-[0.7rem]'>
						Date of birth
					</p>
					<p className=' font-bold text-sm'>
						{profData?.dob_string && profData?.dob_string}
					</p>
				</div>
				<div className=' max-md:border-b max-md:border-b-bluee max-md:w-full max-md:mb-[1rem]'>
					<p className=' text-text_gray font-light mb-[0.5rem] text-[0.7rem]'>
						Gender
					</p>
					<p className=' font-bold text-sm'>
						{profData?.gender_string && profData?.gender_string}
					</p>
				</div>
				<div className=' max-md:border-b max-md:border-b-bluee max-md:w-full max-md:mb-[1rem]'>
					<p className=' text-text_gray font-light mb-[0.5rem] text-[0.7rem]'>
						Country
					</p>
					<p className=' font-bold text-sm'>
						{profData?.country_string && profData?.country_string}
					</p>
				</div>
				<div className=' max-md:border-b max-md:border-b-bluee max-md:w-full max-md:mb-[1rem]'>
					<p className=' text-text_gray font-light mb-[0.5rem] text-[0.7rem]'>
						Address
					</p>
					<p className=' font-bold text-sm'>
						{profData?.address && profData?.address}
					</p>
				</div>
				<div className=' max-md:hidden'></div>
			</div>

			<div className='py-[1rem] border-b border-b-bluee flex justify-between max-md:border-b-0 max-md:flex-col max-md:py-[0rem]'>
				<div className=' max-md:border-b max-md:border-b-bluee max-md:w-full max-md:mb-[1rem]'>
					<p className=' text-text_gray font-light mb-[0.5rem] text-[0.7rem]'>
						Height in cm
					</p>
					<p className=' font-bold text-sm'>
						{medData?.height && medData?.height}cm
					</p>
				</div>
				<div className=' max-md:border-b max-md:border-b-bluee max-md:w-full max-md:mb-[1rem]'>
					<p className=' text-text_gray font-light mb-[0.5rem] text-[0.7rem]'>
						Weight in kg
					</p>
					<p className=' font-bold text-sm'>
						{medData?.weight && medData?.weight}kg
					</p>
				</div>
				<div className=' max-md:border-b max-md:border-b-bluee max-md:w-full max-md:mb-[1rem]'>
					<p className=' text-text_gray font-light mb-[0.5rem] text-[0.7rem]'>
						Blood group
					</p>
					<p className=' font-bold text-sm'>
						{medData?.blood_group && medData?.blood_group.group}
					</p>
				</div>
				<div className=' max-md:border-b-0 max-md:border-b-bluee max-md:w-full max-md:mb-[1rem]'>
					<p className=' text-text_gray font-light mb-[0.5rem] text-sm'></p>
					<p className=' font-bold'></p>
				</div>
			</div>

			<form
				className=' grid pt-[2rem] w-[20rem]'
				onSubmit={handlePasswordUpdate}>
				<h1 className=' text-primary font-bold text-xl mb-[1rem]'>
					Change Password
				</h1>

				<label
					htmlFor='oldPass'
					className=' text-sm text-text_gray mb-[0.5rem]'>
					Old Password
				</label>
				<div className='relative'>
					<input
						type={isOld ? "text" : "password"}
						name='oldPass'
						id='oldPass'
						value={oldPass}
						className=' border border-bluee bg-input_bg rounded-md p-[0.5rem] mb-[1rem] w-full text-lg placeholder:text-7xl'
						onChange={(e) => setOldPass(e.target.value)}
					/>
					<div className=' absolute top-[0.7rem] right-[0.5rem]'>
						{isOld ? (
							<BiHide className=' text-xl' onClick={() => setIsOld(!isOld)} />
						) : (
							<BiShow className=' text-xl' onClick={() => setIsOld(!isOld)} />
						)}
					</div>
				</div>

				<label
					htmlFor='newPass'
					className=' text-sm text-text_gray mb-[0.5rem]'>
					New Password
				</label>

				<div className='relative'>
					<input
						type={isNew ? "text" : "password"}
						name='newPass'
						id='newPass'
						value={newPass}
						className=' border border-bluee bg-input_bg rounded-md p-[0.5rem] mb-[2rem] w-full text-lg'
						onChange={(e) => setNewPass(e.target.value)}
					/>
					<div className='absolute top-[0.7rem] right-[0.5rem]'>
						{isNew ? (
							<BiHide className=' text-xl' onClick={() => setIsNew(!isNew)} />
						) : (
							<BiShow className=' text-xl' onClick={() => setIsNew(!isNew)} />
						)}
					</div>
				</div>

				<button className='bg-primary text-white py-[0.5rem] text-center w-full rounded-md'>
					Update Password
				</button>
			</form>

			<div
				className=' items-center cursor-pointer text-black hidden max-md:flex mt-[2rem]'
				onClick={() => logout()}>
				<HiOutlineLogout className=' mr-[0.5rem] max-md:mr-0 max-md:text-3xl max-md:mb-[0.5rem] text-black' />{" "}
				Logout
			</div>

			<div className=' flex justify-end max-md:justify-start'>
				<Link
					to='/account/delete-account'
					className=' text-danger flex items-center max-md:mt-[2rem] cursor-pointer'>
					<p className=' mr-[1rem]'>Delete Account</p>
					<BsPersonFillX className=' text-2xl ' />
				</Link>
			</div>

			<div className=' mt-[3rem]'>
				<p className=' text-[0.7rem] text-gray-500'>version 1.09</p>
			</div>
		</div>
	);
};

export default Profile;
