import Enroll from "components/Enroll";
import React from "react";

const Enrollee = () => {
	return (
		<div>
			<Enroll />
		</div>
	);
};

export default Enrollee;
