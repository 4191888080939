import React, { useState, useContext, useEffect } from 'react'
import Modal from './Modal'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { Toaster, toast } from 'react-hot-toast'
// import { API_URL } from './config'
import { useHistory, useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { MdCancel } from 'react-icons/md'
import GoBack from './GoBack'
import AuthContext from './context/AuthContext'

const DiscountCheck = ({ details }) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const [modal, setModal] = useState(false)
	const [id, setId] = useState()
	const [code, setCode] = useState()
	const [cardNumber, setCardNumber] = useState()
	const [expiry, setExpiry] = useState()
	const [message, setMessage] = useState('')
	const [isError, setIsError] = useState(false)
	const [cardRes, setCardRes] = useState()

	const { isLoading, setIsLoading } = useContext(AuthContext)

	const history = useHistory()
	const cookies = new Cookies()

	const search = useLocation().search

	const searchParams = new URLSearchParams(search)

	

	useEffect(() => {
		handleDiscount()
	}, [])

	const handleDiscount = async () => {
		if (searchParams.get('reference')) {
			setIsLoading(true)
			const res = await fetch(`${API_URL}//finance/discount/bank/verify`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${cookies.get('vsm-authorization')}`,
				},
				body: JSON.stringify({
					reference: searchParams.get('reference'),
				}),
			})
			const data = await res.json()


			setIsLoading(false)
		}
	}

	const handleOnboard = async (e) => {
		e.preventDefault()
		const toastLoading = toast.loading('Loading...')

		const res = await fetch(
			`${API_URL}/finance/discount/bank/verify-card/paystack`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${cookies.get('vsm_authorization')}`,
				},
				body: JSON.stringify({
					gateway: details?.plan_ids[0].id,
					card_number: cardNumber,
					expiry,
					platform: 'live',
				}),
			}
		)

		const data = await res.json()

	

		if (res.ok) {
			setCardRes(data)

			if (data.confirmed) {
		
				setModal(true)
				setMessage(data.message)
			} else {
		
				setIsError(true)
				setMessage(data.message)
			}

			setMessage(data.message)
		} else {
			if (data.company_id && data.enrollee_number && data.plan_code) {
				toast.error('All field are invalid!', {
					duration: 6000,
				})
			} else if (data.company_id && data.enrollee_number) {
				toast.error('Company id & enrollee number are invalid!', {
					duration: 6000,
				})
			} else if (data.company_id && data.plan_code) {
				toast.error('Company id & plan code are invalid!', {
					duration: 6000,
				})
			} else if (data.plan_code && data.enrollee_number) {
				toast.error('Plan code and enrollee number are invalid!', {
					duration: 6000,
				})
			} else if (data.company_id) {
				toast.error(data.company_id[0], {
					duration: 6000,
				})
			} else if (data.plan_code) {
				toast.error(data.plan_code[0], {
					duration: 6000,
				})
			} else if (data.enrollee_number) {
				toast.error(data.enrollee_number[0], {
					duration: 6000,
				})
			}
		}

		toast.dismiss(toastLoading)
	}

	const handleModal = () => {
		setModal(false)

		setIsError(false)

		// history.push("/plans");
		window.location.replace(cardRes?.payment_info.authorization_url)
	}

	return (
		<div className=' w-[70vw] mx-auto max-2xl:w-[60vw] max-xl:mx-auto max-lg:mx-auto max-md:mx-[auto] max-md:w-[90vw] max:md:w-[20rem] max-md:pb-[10rem] pt-[2rem]'>
			<p className='medium top-0 py-[1rem] fixed z-[1] bg-white w-[100vw] text-2xl left-[15rem] max-md:left-[0rem] max-md:pl-[6rem] max-md:z-[0] max-md:bg-white max-md:w-[100vw] max-md:h-[4rem]'>
				Verify Your Card
			</p>

			{modal && (
				<Modal
					icon={<BsFillCheckCircleFill className=' text-primary text-3xl' />}
					text={'You are eligible for a discount.'}
					header={'Congratulations!'}
					btn={true}
					btnType={'text'}
					btnText={'Checkout'}
					btnCount={1}
					color={'text-primary medium'}
					event={() => handleModal()}
				/>
			)}
			{isError && (
				<Modal
					icon={<MdCancel className=' text-[#FC9249] text-3xl' />}
					text={message}
					header={'Card Not Eligible'}
					btn={true}
					btnType={'text'}
					btnText={'Ok'}
					btnCount={1}
					color={'text-primary medium'}
					event={() => setIsError(false)}
				/>
			)}

			<div className=' w-[50%] max-2xl:w-[70%] max-md:w-full'>
				<Toaster position='top-center' reverseOrder={false} />
				<div className=''>
					<GoBack />
					<p className=' flex items-center mb-[1rem]'>
						<img
							src={details?.bank.logo_thumb}
							alt=''
							className=' w-[5rem] mr-[0.5rem]'
						/>{' '}
						{details.description}
					</p>
					<p className=' medium mb-[1rem]'>
						We do not store any of your card details in any way.
					</p>
					<form action=' grid w-[90%]' onSubmit={handleOnboard}>
						<div className=' mb-[1rem]'>
							<label htmlFor='card' className=' text-text_gray'>
								Card Number
							</label>
							<input
								type='text'
								name='card'
								id='card'
								value={cardNumber}
								onChange={(e) => setCardNumber(e.target.value)}
								className=' text-lg px-[1rem] py-[0.5rem] w-full border border-input_border rounded-lg mt-[0.5rem] bg-light_blue'
							/>
						</div>
						<div className=' mb-[1rem]'>
							<label htmlFor='expiry' className=' text-text_gray'>
								Expiry Date
							</label>
							<input
								type='text'
								name='expiry'
								id='expiry'
								value={expiry}
								onChange={(e) => setExpiry(e.target.value)}
								className=' text-lg px-[1rem] py-[0.5rem] w-full border border-input_border rounded-lg mt-[0.5rem] bg-light_blue'
							/>
						</div>

						<button className=' bg-primary text-white border border-input_border py-[1rem] rounded-lg w-full mt-[2rem]'>
							Verify
						</button>
					</form>
				</div>
			</div>
		</div>
	)
}

export default DiscountCheck
