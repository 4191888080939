import ResultTemplate from 'components/resultTemplate'
import React from 'react'

const ResultDownload = () => {
	return (
		<div>
			<ResultTemplate />
		</div>
	)
}

export default ResultDownload
