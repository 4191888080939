import Personal from "components/Personal";
import React, { useContext } from "react";
import AuthContext from "components/context/AuthContext";

const Individual = () => {
	return (
		<div>
			<Personal />
		</div>
	);
};

export default Individual;
