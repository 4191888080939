// import indicatorValue from './../common/indicator'
// import { themeColor } from '../styles/Theme'
import styled from 'styled-components'
// import universalSettings from './../config/universalSettings'
import './History.scss'
import './General.scss'
import stress from 'assets/images/stressLevel.png'
import oxygen from 'assets/images/oxygenSat.png'
import heart from 'assets/images/heartRate.png'
import respRate from 'assets/images/rRate.png'
import blood from 'assets/images/bloodPressure.png'
import logo from 'assets/images/logoPdf.png'

const ResultTemplate = ({ data, vitals }) => {

	const Container = styled.div`
		width: 50rem;

		.warn-text {
			color: #fc9249;
		}

		.normal-text {
			color: #0f66d2;
		}

		.high-text {
			color: #ff2e00;
		}

		.heading {
			text-align: center;
		}
		.heading h1 {
			font-weight: bold;
		}
		.bold {
			font-weight: bold;
		}
		.mt-40 {
			margin-top: 40px;
		}
		.vsm_blue {
			color: #0c327a;
		}
		.subject {
			text-align: center;
			color: #0c327a;
			text-transform: uppercase;
			font-weight: bold;
		}
		.content {
			background-color: #f5f8ff;
			padding: 60px 20px;
		}
		.hra {
			display: flex;
			width: 80%;
			max-width: 600px;
			align-items: center;
			margin: 20px auto;
			padding: 10px 20px;
			color: #ffffff;
			border-radius: 10px;
			background: #219653;
		}
		.hra .status {
			width: 80px;
			height: 80px;
			padding: 24px 0;
			border-radius: 50%;
			border: 5px solid #ffffff;
			text-transform: uppercase;
			text-align: center;
			margin-right: 16px;
		}
		.hra .title {
			font-size: 18px;
			font-weight: bold;
		}
		.reading {
			display: flex;
			width: 80%;
			max-width: 600px;
			justify-content: space-between;
			align-items: center;
			margin: 20px auto;
			border-radius: 8px;
			background-color: #fff;
			padding: 20px 15px;
			box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.3);
			-webkit-box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.3);
			-moz-box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.3);
		}
		.reading img {
			width: 60px;
			height: auto;
			margin-right: 20px;
		}
		.reading .title {
			color: #0c327a;
			font-size: 16px;
		}
		.reading .recording {
			font-size: 24px;
			font-weight: bold;
		}
	`

	const universalSettings = {
		appName: 'SignalADoc VSM',
	}

	const indicatorValue = {
		ok: 'ok',
		warning: 'warning',
		danger: 'danger',
	}

	const setColor = (indicator) => {
		console.log(indicator)
		switch (indicator) {
			case indicatorValue.ok:
				return 'normal-text'
			case indicatorValue.warning:
				return 'warn-text'
			case indicatorValue.danger:
				return 'danger-text'
			default:
				return 'normal-text'
		}
	}

	const bgColor = (indicator) => {
		switch (indicator) {
			case indicatorValue.ok:
				return 'normal'
			case indicatorValue.warning:
				return 'warning'
			case indicatorValue.danger:
				return 'danger'
			default:
				return 'bg-white'
		}
	}

	return (
		<Container className='content '>
			<div className='container bg-light_blue p-[2rem] '>
				<div className='row'>
					<div className='col-md-6 offset-md-3'>
						<div className='heading grid justify-items-center'>
							<img src={logo} alt={`${data?.app}`} />
							<br />
							<h1 className='h4 vsm_blue'>{data?.app}</h1>
							<p className='text-muted'>{data?.website}</p>
						</div>
					</div>
				</div>

				<div className='row'>
					<div className='col'>
						<h5 className='bold mt-40'>{data?.user_name}</h5>
						<p className='vsm_blue'>{vitals?.date_time}</p>
						<h1 className='h2 subject'>Vital Signs Readings</h1>
						<p className=' text-sm'>
							Below are the vital sign reading as recorded by{' '}
							<span className='bold'>{data?.app}</span> app on{' '}
							{vitals?.date_time}
						</p>
					</div>
				</div>

				<div className='row'>
					<div className='col'>
						<div
							className='hra'
							style={{
								backgroundColor: bgColor(
									vitals?.health_risk_assessment_indicator
								),
							}}
						>
							<div className='status'>
								{vitals?.health_risk_assessment_display}
							</div>
							<div className='title'>Health Risk Assessment</div>
						</div>
						<div className='reading'>
							<div className=' flex items-center'>
								<img src={heart} alt='' />
								<span className='title'>Heart Rate</span>
							</div>
							<div
								className={` ${
									vitals?.beats_per_minute_indicator === 'warning' &&
									'warn-text'
								} ${
									vitals?.beats_per_minute_indicator === 'danger' && 'high-text'
								} ${
									vitals?.beats_per_minute_indicator === 'ok' && 'normal-text'
								} value regular recording`}
								style={{
									color: setColor(vitals?.beats_per_minute_indicator),
								}}
							>
								{vitals?.beats_per_minute_display}
							</div>
						</div>
						<div className='reading'>
							<div className=' flex items-center'>
								<img src={respRate} alt='' />
								<span className='title'>Respiratory Rate</span>
							</div>
							<div
								className={` ${
									vitals?.respiration_rate_indicator === 'warning' &&
									'warn-text'
								} ${
									vitals?.respiration_rate_indicator === 'danger' && 'high-text'
								} ${
									vitals?.respiration_rate_indicator === 'ok' && 'normal-text'
								} value regular recording`}
								style={{
									color: setColor(vitals?.respiration_rate_indicator),
								}}
							>
								{vitals?.respiration_rate_display}
							</div>
						</div>
						<div className='reading'>
							<div className=' flex items-center'>
								<img src={stress} alt='' />
								<span className='title'>Mental Stress</span>
							</div>
							<div
								className={` ${
									vitals?.stress_status_indicator === 'warning' && 'warn-text'
								} ${
									vitals?.stress_status_indicator === 'danger' && 'high-text'
								} ${
									vitals?.stress_status_indicator === 'ok' && 'normal-text'
								} value regular recording`}
								style={{
									color: setColor(vitals?.stress_status_indicator),
								}}
							>
								{vitals?.stress_status_display}
							</div>
						</div>
						<div className='reading'>
							<div className=' flex items-center'>
								<img src={oxygen} alt='' />
								<span className='title'>Oxygen Saturation</span>
							</div>
							<div
								className={` ${
									vitals?.oxygen_indicator === 'warning' && 'warn-text'
								} ${vitals?.oxygen_indicator === 'danger' && 'high-text'} ${
									vitals?.oxygen_indicator === 'ok' && 'normal-text'
								} value regular recording`}
								style={{ color: setColor(vitals?.oxygen_indicator) }}
							>
								{vitals?.oxygen_display}
							</div>
						</div>
						<div className='reading'>
							<div className=' flex items-center'>
								<img src={blood} alt='' />
								<span className='title'>Blood Pressure</span>
							</div>
							<div
								className={` ${
									vitals?.blood_pressure_indicator === 'warning' && 'warn-text'
								} ${
									vitals?.blood_pressure_indicator === 'danger' && 'high-text'
								} ${
									vitals?.blood_pressure_indicator === 'ok' && 'normal-text'
								} value regular recording`}
								style={{
									color: setColor(vitals?.blood_pressure_indicator),
								}}
							>
								{vitals?.blood_pressure_display}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	)
}

{
	/* <!-- Optional JavaScript -->
        <!-- jQuery first, then Popper.js, then Bootstrap JS -->
        <script src="https://code.jquery.com/jquery-3.4.1.slim.min.js" integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n" crossorigin="anonymous"></script>
        <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js" integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6" crossorigin="anonymous"></script> */
}

export default ResultTemplate
