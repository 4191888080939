export const API_URL = 'https://app.signaladoc.com/api/v2/vsm'
export const RAVE_KEY = 'FLWPUBK_TEST-a9f0d37e08a7edf5f9b28e961ad9b25d-X'
export const PAYSTACK_PUBLIC_KEY =
'pk_test_91bbf2a01e49c9caef3c8c0dce822569f8263ae4'

// 
// export const API_URL = "https://app.signaladoc.com/api/v2/vsm";
// export const RAVE_KEY = "FLWPUBK-dd90987b788ae3ac1fc04865996d3525-X";
// export const PAYSTACK_PUBLIC_KEY =
	// "pk_live_532a00bd90819b463b9bcd3bee6cf147402e44f3";

export const GOOGLE_KEY =
	"598136990860-etprh808l9cfpuq8sblqgqoj6q5takpn.apps.googleusercontent.com";

export const PUBLIC_URL = "https://vsm2.signaladoc.ng";
export const NODE_ENV = "production";
