import { API_URL } from 'components/config'
import { initializeApp } from 'firebase/app'
// import { getAnalytics } from 'firebase/analytics'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { useState, useContext, useEffect } from 'react'
import Cookies from 'universal-cookie'
import AuthContext from 'components/context/AuthContext'
import { isMobile, osName } from 'react-device-detect'

// export const Sendrequest = () => {
// 	console.log('Requesting User Permission......')
// 	Notification.requestPermission().then((permission) => {
// 		if (permission === 'granted') {
// 			console.log('Notification User Permission Granted.')
// 		} else {
// 			console.log('User Permission Denied.')
// 		}
// 	})
// }

// Sendrequest()

const cookies = new Cookies()

const platform = require('platform')

const firebaseConfig = {
	apiKey: 'AIzaSyCiIMvGNKG9kcm-uBRMqQX8ICsL6ctI4HQ',
	authDomain: 'signaladoc-vsm.firebaseapp.com',
	projectId: 'signaladoc-vsm',
	storageBucket: 'signaladoc-vsm.appspot.com',
	messagingSenderId: '598136990860',
	appId: '1:598136990860:web:363d7195709d48aa915fa9',
	measurementId: 'G-8Y9W9Y91PX',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)

const messaging = getMessaging(app)

export const requestForToken = () => {
	console.log(parseFloat(platform.os.version))
	if (platform.os.family === 'iOS') {
		console.log('Not Supported!')
	} else {
		return getToken(messaging, {
			vapidKey:
				'BHS7TBykUeFm7-IsWdWH1i8BbtZT3138X634f20HXhNkUIUIv-ZCiRUPKdqr8NX_k9qUx77FDait1is20wzY4Ak',
		})
			.then((currentToken) => {
				if (currentToken) {
					if (cookies.get('vsm_authorization')) {
						handleFcm(currentToken)
					}
					console.log('current token for client: ', currentToken)
					// Perform any other neccessary action with the token
				} else {
					// Show permission request UI
					console.log(
						'No registration token available. Request permission to generate one.'
					)
				}
			})
			.catch((err) => {
				console.log('An error occurred while retrieving token. ', err)
			})
	}
}

const handleFcm = async (token) => {
	const res = await fetch(`${API_URL}/user/fcm/token/update`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${cookies.get('vsm_authorization')}`,
		},
		body: JSON.stringify({
			fcm_token: token,
		}),
	})

	const data = await res.json()

	console.log(data)
}

export const Sendrequest = () => {
	if (platform.os.family === 'iOS') {
		console.log('Not Supported!')
	} else {
		console.log('Requesting User Permission......')
		Notification.requestPermission().then((permission) => {
			if (permission === 'granted') {
				console.log('Notification User Permission Granted.')

				return getToken(messaging, {
					vapidKey: `BHS7TBykUeFm7-IsWdWH1i8BbtZT3138X634f20HXhNkUIUIv-ZCiRUPKdqr8NX_k9qUx77FDait1is20wzY4Ak`,
				})
					.then((currentToken) => {
						if (currentToken) {
							console.log('Client Token')
						} else {
							console.log('Failed to generate the registration token.')
						}
					})
					.catch((err) => {
						console.log(
							'An error occurred when requesting to receive the token.',
							err
						)
					})
			} else {
				console.log('User Permission Denied.')
			}
		})
	}
}

// Sendrequest()

export const onMessageListener = () =>
	new Promise((resolve) => {
		onMessage(messaging, (payload) => {
			console.log('payload', payload)
			resolve(payload)
		})
	})

// export default app
