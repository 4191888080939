import React, { useContext, useEffect } from 'react'
import './General.scss'
// import { API_URL } from './config'
import AuthContext from './context/AuthContext'
import { useHistory } from 'react-router-dom'
import { BsPersonHeart } from 'react-icons/bs'

const TelcoSub = () => {
	const { telcoPlans, setTelcoSub, setIsPlansNav, handleFinance } =
		useContext(AuthContext)



	useEffect(() => {
		setIsPlansNav(false)

		handleFinance()
	}, [])

	const history = useHistory()

	const handleClick = () => {
		setTelcoSub(false)
		history.push('/home')

		// console.log('working')
	}

	// console.log(telc)

	return (
		<div className='  w-[60vw] mx-auto pt-[10rem] max-md:w-[90vw] max-md:pt-[5rem] max-md:mb-[5rem]'>
			<p className='medium fixed text-2xl z-[4] top-[2rem] max-md:pl-[6rem] max-md:w-[100vw] max-md:bg-white max-md:h-[5rem] max-md:left-[0rem] max-md:top-0 max-md:pt-[2rem] max-md:z-[0]'>
				Telco Plans
			</p>

			<div className=' w-[80vw] grid grid-cols-3 justify-items-center items-center p-[1rem] mx-auto max-md:grid-cols-2 max-sm:grid-cols-1 max-md:w-[90vw] max-lg:grid-cols-2'>
				{telcoPlans?.map((e) => (
					<div
						className=' bg-white shadow-2xl mb-[1rem] p-[1rem] rounded-md max-md:px-[0.5rem] max-md:py-[1rem] flex items-center w-[15rem] mx-auto max-md:w-[40vw] max-sm:w-[70vw] max-md:p-[2rem]'
						key={e.id}
					>
						<div className='h-[3rem] w-[3rem] rounded-full bg-light_blue flex items-center justify-center text-3xl text-primary mr-[1rem]'>
							<BsPersonHeart />
						</div>
						<div className=' grid'>
							<h2 className=' bold'>{e.period_string}</h2>
							<p className=' mb-[0.5rem]'>
								<span
									dangerouslySetInnerHTML={{
										__html: e.amount_formatted,
									}}
									className=' bold text-primary text-2xl mt-[0.5rem] mb-[0.5rem]  max-md:text-2xl max-md:mb-[1rem]'
								></span>
							</p>
							<p className=' mb-[1rem]'>{e.send}</p>
							<div className='' onClick={() => handleClick()}>
								<a
									href={`sms:${e.shortcode}?&body=${e.keyword}`}
									className=' text-white medium rounded-md p-[0.5rem] bg-primary transition-all duration-75 max-md:text-sm'
								>
									Choose Plan
								</a>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default TelcoSub
