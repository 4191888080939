import BundlePlan from 'components/BundlePlan'
import React from 'react'

const Bundle = () => {
	return (
		<div>
			<BundlePlan />
		</div>
	)
}

export default Bundle
