import { useEffect, useRef, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@fluentui/react-northstar";
import classNames from "classnames";
import useFaceMesh from "hooks/useFaceMesh";
import useRPPG from "hooks/useRPPG";
import useNotification from "hooks/useNotification";
import {
	LoadingScreen,
	CheckFps,
	ImageQuality,
	Notification,
	Info,
	TextMessage,
	ProgressType,
} from "tabs/CaptureTab/Components";
import {
	NOTIFICATION_FACE_ORIENT_WARNING,
	NOTIFICATION_FACE_SIZE_WARNING,
	NOTIFICATION_INTERFERENCE_WARNING,
	NOTIFICATION_NO_FACE_DETECTED,
} from "helpers/notification";
import "./Capture.scss";
import Cookies from "universal-cookie";
import AuthContext from "components/context/AuthContext";
import { toast } from "react-hot-toast";
import Modal from "components/Modal";
import "components/General.scss";
import { API_URL } from "components/config";
import { TbCameraRotate } from "react-icons/tb";

export function Capture() {
	const videoElement = useRef<HTMLVideoElement>(null);
	const canvasElement = useRef<HTMLCanvasElement>(null);
	const [isReady, setIsReady] = useState<boolean>(true);
	const [isCancel, setIsCancel] = useState<boolean>(true);
	const [isCancelModal, setIsCancelModal] = useState<boolean>(false);
	const [isDone, setIsDone] = useState<boolean>(false);

	const history = useHistory();

	const cookies = new Cookies();

	const {
		resultReading,
		setResultReading,
		user,
		canCapture,
		handleInit,
		isSubscribe,
		setIsSubscribe,
		setProfile,
		setIsCamera,
		isCamera,
		medData,
		profData,
		setChooseCamera,
	} = useContext(AuthContext);

	useEffect(() => {
		// handleInit()

		setProfile(true);

		console.log(canCapture);

		if (!user?.updated_profile) {
			history.push("/account/edit");
		}

		if (!user) {
			history.push("/login");
		}

		setTimeout(() => {
			if (canCapture) {
				setIsSubscribe(false);
			} else {
				setIsSubscribe(true);
				history.push("/home");
			}
		}, 3000);
	}, [canCapture, setIsSubscribe, handleInit]);

	const [size, setSize] = useState<{ width: number; height: number }>({
		width: 0,
		height: 0,
	});

	const { message, addNotification, clearAllNotifications } = useNotification();

	const processingFaceMesh = useRef(false);

	const [useFrontCamera, setUseFrontCamera] = useState(true);

	const onUnsupportedDeviceCb = () => {
		stopHandler();
		history.push("/capture/not-supported");
	};

	// const handleResult = async () => {

	// };

	const onCalculationEndedCb = async () => {
		stopHandler();
		closeCamera();
		cameraInstance?.stop();

		history.push("/capture/results", {
			rppgData,
			isAllDataCalculated,
			type: "capture",
		});
	};

	const onFaceDetectionChange = (faceDetected: boolean) => {
		if (processing && !faceDetected) {
			addNotification(NOTIFICATION_NO_FACE_DETECTED);
		}
	};

	const onInterferenceWarningCb = () => {
		// addNotification(NOTIFICATION_INTERFERENCE_WARNING);
		// setTimeout(() => {
		//   stopHandler();
		//   history.push("/capture/bad-conditions");
		// }, 3000);
	};

	const onFaceOrientWarningCb = () =>
		processing && addNotification(NOTIFICATION_FACE_ORIENT_WARNING);

	const onFaceSizeWarningCb = () =>
		processing && addNotification(NOTIFICATION_FACE_SIZE_WARNING);

	// Facemesh init
	const { cameraInstance } = useFaceMesh({
		videoElement,
		canvasElement,
		processing: processingFaceMesh,
		onFaceDetectionChange,
	});

	// Rppg init
	const {
		rppgData,
		ready,
		rppgInstance,
		isAllDataCalculated,
		imageQualityFlags,
		progressType,
		processing,
		checkFps,
		start,
		stop,
		closeCamera,
		switchCamera,
		// threeValuesCalculated,
	} = useRPPG({
		videoElement,
		// onUnsupportedDeviceCb,
		onAllDataCalculatedCb: onCalculationEndedCb,
		onCalculationEndedCb,
		onInterferenceWarningCb,
		onFaceOrientWarningCb,
		onFaceSizeWarningCb,
	});

	useEffect(() => {
		handleInit();

		// stopHandler()

		if (!rppgInstance || !ready) {
			return;
		}
		console.log("useEffect - rppg - initialized");
		// @ts-ignore
		const { width, height } = rppgInstance.rppgCamera;
		setSize({ width, height });
	}, [ready, rppgInstance]);

	useEffect(() => {
		// if (canCapture) {
		processingFaceMesh.current = processing;
		// }
	}, [processing]);

	const startHandler = () => {
		start();
	};

	// const handleDone = () => {

	// };

	const stopHandler = () => {
		setIsDone(true);
		setIsCancelModal(false);
		clearAllNotifications();
		stop();

		// closeCamera();
		// cameraInstance?.stop();
	};

	const startButtonHandler = () => {
		if (processing) {
			// handleCancel();
			setIsCancelModal(true);
		} else {
			startHandler();
		}
	};

	// const startButtonHandler = () => {
	// 	if (processing) {
	// 		stopHandler();
	// 	} else {
	// 		startHandler();
	// 	}
	// };

	const handleCameraChange = () => {
		setUseFrontCamera(!useFrontCamera);
		switchCamera(useFrontCamera);
	};

	const handleCamera = () => {
		// setUseFrontCamera(!useFrontCamera)
		// switchCamera(useFrontCamera)

		// setUseFrontCamera(!useFrontCamera)
		// switchCamera(useFrontCamera)

		setIsCamera(false);

		setChooseCamera(true);

		// history.push(
		// 	`/capture/?weight=${medData?.weight}&height=${medData?.height}&age=${
		// 		profData?.age
		// 	}&camera=${true}`
		// )
	};

	// console.log('here is the rpg data', rppgData);

	return (
		<>
			{/* {isCamera && (
				<>
					<div className=' absolute hidden max-md:block mt-[1rem] top-[3rem] right-[1rem] z-[20] cursor-pointer'>
						<TbCameraRotate
							onClick={() => handleCameraChange()}
							className=' text-4xl relative ml-[2rem]'
						/>
					</div>
					<Modal
						text={'Set your camera'}
						btn={true}
						btnType={'primary'}
						btnText={'Change Camera'}
						btnCount={1}
						color={'text-white bg-primary rounded-md px-[2rem] medium'}
						event={() => handleCameraChange()}
						icon=''
						px=''
						header=''
						otherEvent=''
						bg=''
						btnText2=''
						btnType2=''
						bg2=''
						color2=''
						px2=''
					/>
				</>
			)} */}

			{!processing && (
				<>
					<div className=' flex items-center justify-center	fixed z-[4] left-0 right-0'>
						<div className=' mt-[1rem]'>
							<h1 className=' bold w-[20%] text-2xl max-md:text-xl'>
								Read Vitals
							</h1>
						</div>
					</div>
					<div className=' fixed hidden max-md:block mt-[1rem] top-[3rem] right-[1rem] z-[4]'>
						<TbCameraRotate
							onClick={() => handleCameraChange()}
							className=' text-4xl relative ml-[2rem]'
						/>
					</div>
				</>
			)}

			{processing && progressType !== ProgressType.CALIBRATING && (
				<div className='  z-[3] w-[50%] flex justify-between medium text-primary mx-auto max-md:w-[90%] max-md:top-[1rem] style'>
					<p
						className={` ${
							rppgData.frameData.instantFps < 4
								? "text-danger"
								: "text-bg_green"
						}`}>
						FPS: {rppgData.frameData.instantFps.toFixed(1)}
					</p>
					<p
						className={` ${
							rppgData.signalQuality.snr < 4 ? "text-danger" : "text-bg_green"
						}`}>
						SNR: {rppgData.signalQuality.snr.toFixed(1)}
					</p>
				</div>
			)}

			<div className='measurement-container'>
				{/* isAllDataCalculated: {String(isAllDataCalculated)}, 
      fps: {JSON.stringify(fps)}<br/>
      ready: {String(ready)},
      faceDetected: {String(faceDetected)}, */}

				{isReady && (
					<Modal
						text={
							"Ready? Please confirm you are seated and at rest before you start the assessment. We recommend that you are seated and rested 5 mins before taking a reading. To also ensure that your readings are accurate, please do not talk during a reading, sit quietly, stay in a well lit environment and keep your face clear of any covering."
						}
						btn={true}
						btnType={"primary"}
						btnText={"Ready"}
						btnCount={1}
						color={"text-white bg-primary rounded-md px-[2rem] medium"}
						event={() => setIsReady(false)}
						icon=''
						px=''
						header=''
						otherEvent=''
						bg=''
						btnText2=''
						btnType2=''
						bg2=''
						color2=''
						px2=''
					/>
				)}

				{isDone && (
					<Modal
						text={"Please note that assessment will not be saved"}
						btn={true}
						btnType={"primary"}
						btnText={"Done"}
						btnCount={1}
						color={"text-white bg-primary rounded-md px-[2rem] medium"}
						event={() => setIsDone(false)}
						icon=''
						px=''
						header=''
						otherEvent=''
						bg=''
						btnText2=''
						btnType2=''
						bg2=''
						color2=''
						px2=''
					/>
				)}
				{isCancelModal && (
					<Modal
						text={"Are you sure you want to cancel your vital signs reading?"}
						btn={true}
						btnType={"primary"}
						btnText={"Don't Cancel"}
						btnCount={2}
						color={
							"text-white bg-primary px-[1rem] rounded-md medium max-md:mb-[1rem]"
						}
						event={() => setIsCancelModal(false)}
						icon=''
						px=''
						header=''
						otherEvent={() => stopHandler()}
						bg=''
						btnText2='Cancel'
						btnType2='text'
						bg2=''
						color2='text-primary medium'
						px2=''
					/>
				)}

				{!ready && <LoadingScreen />}

				<div className='measurement-wrapper'>
					<div className='video-container max-md:h-[20rem]'>
						<div className='video-wrapper'>
							<video
								ref={videoElement}
								autoPlay
								playsInline
								muted
								className={classNames({
									video: true,
									horizontal: size.width > size.height,
									vertical: size.width <= size.height,
									invert: useFrontCamera,
								})}></video>
							<canvas ref={canvasElement} className='canvas'></canvas>
						</div>
					</div>

					{/* {processing && <ImageQuality imageQualityFlags={imageQualityFlags} />} */}
					<div className=' information'>
						<div className='start-block-wrapper'>
							{message ? (
								<Notification message={message} />
							) : (
								<>
									<TextMessage progressType={progressType} />

									{checkFps && <CheckFps />}
								</>
							)}

							{processing && progressType !== ProgressType.CALIBRATING && (
								<Info rppgData={rppgData} />
							)}
						</div>

						<Button
							primary
							// fluid
							content={processing ? "Cancel reading" : "Start"}
							disabled={!ready}
							loading={!ready}
							onClick={startButtonHandler}
							className=' bg-primary text-white rounded-md py-[0.5rem] px-[1rem] w-[20rem] mt-[15rem]'
						/>
					</div>
				</div>
			</div>
		</>
	);
}
