import React from 'react'
import { useHistory } from 'react-router-dom'
import { MdArrowBackIosNew } from 'react-icons/md'

const GoBack = () => {
	const history = useHistory()

	return (
		<div>
			<div className=' mb-[1rem] mt-[1rem]'>
				<MdArrowBackIosNew
					className={' text-3xl cursor-pointer'}
					onClick={() => history.goBack()}
				/>
			</div>
		</div>
	)
}

export default GoBack
