import React from 'react'
import image from 'assets/images/animation.png'

const Learn = () => {
	return (
		<div className=''>
			<div className=' w-[70vw] mx-auto pt-[5rem] grid justify-items-center'>
				<img src={image} alt='' className=' w-[30rem]' />
				<h1 className='font-bold text-center'>Under Construction</h1>
			</div>
		</div>
	)
}

export default Learn
