import React, { useState, useEffect, useContext } from "react";
import { AiOutlineUser, AiFillCheckCircle } from "react-icons/ai";
import { BiChevronRight, BiTargetLock } from "react-icons/bi";
import { BsFillCreditCard2BackFill } from "react-icons/bs";

import "./General.scss";
import AuthContext from "./context/AuthContext";
import { useHistory } from "react-router-dom";
import Buttons from "./Buttons";

const TourSteps = ({ setStartTour }) => {
	const { user, canCapture, medData, profData, handleInit, handleProfile } =
		useContext(AuthContext);

	useEffect(() => {
		// handleProfile()
		// handleInit()
	}, []);

	const history = useHistory();

	const handleProf = () => {
		// handleProfile()
		// handleCapture()

		history.push("/account/edit");
	};

	const handlePlan = () => {
		history.push("/plans/explore");

		// handleProfile()
		// handleCapture()
	};

	const handleCapture = () => {
		// handleProfile()
		// handleCapture()

		history.push(
			`/capture/?weight=${medData?.weight}&height=${medData?.height}&age=${profData?.age}`,
		);

		// window.location.reload()
	};

	return (
		<div className=' fixed top-0 right-0 z-[10] bg-black bg-opacity-40 w-[100vw] h-[100vh] flex items-center justify-center'>
			<div className=' rounded-md bg-white w-[40rem] flex flex-col items-center py-[1rem]'>
				<div className='w-full border-b border-b-1 py-[1rem]'>
					<div
						className=' flex items-center justify-between w-[90%] mx-auto cursor-pointer'
						onClick={() => handleProf()}>
						<AiOutlineUser className=' text-primary text-2xl bg-light_blue h-[3rem] w-[3rem] rounded-full p-[0.5rem]' />
						<div className=' w-[60%] mx-auto'>
							<p className=' medium '>Complete your profile setup</p>
							<p className=' text-sm'>
								This a very important step to get accurate readings.
							</p>
						</div>
						{user?.updated_profile ? (
							<AiFillCheckCircle className=' text-primary text-3xl ' />
						) : (
							<BiChevronRight className=' h-[2rem] w-[2rem] p-[0.3rem] text-lg bg-light_blue rounded-full' />
						)}
					</div>
				</div>
				<div className='w-full border-b border-b-1 py-[1rem]'>
					<div
						className=' flex items-center justify-between w-[90%] mx-auto cursor-pointer'
						onClick={() => handlePlan()}>
						<BsFillCreditCard2BackFill className=' text-primary text-2xl bg-light_blue h-[3rem] w-[3rem] rounded-full p-[0.5rem]' />
						<div className=' w-[60%] mx-auto'>
							<p className=' medium '>Pick your best plan</p>
							<p className=' text-sm'>
								Our plans are specially tailored to meet your individual needs.
							</p>
						</div>
						{canCapture ? (
							<AiFillCheckCircle className=' text-primary text-3xl ' />
						) : (
							<BiChevronRight className=' h-[2rem] w-[2rem] p-[0.3rem] text-lg bg-light_blue rounded-full' />
						)}
					</div>
				</div>
				<div className='w-full py-[1rem]'>
					<div
						className=' flex items-center justify-between w-[90%] mx-auto cursor-pointer'
						onClick={() => handleCapture()}>
						<BiTargetLock className=' text-primary text-2xl bg-light_blue h-[3rem] w-[3rem] rounded-full p-[0.5rem]' />
						<div className=' w-[60%] mx-auto'>
							<p className=' medium '>Start Capturing</p>
							<p className=' text-sm'>
								Now you can keep track of your vital signs at the click of a
								button!
							</p>
						</div>
						<BiChevronRight className=' h-[2rem] w-[2rem] p-[0.3rem] text-lg bg-light_blue rounded-full' />
					</div>
				</div>
				<br />

				<div className='flex justify-between items-center w-[80%]'>
					<Buttons
						text={"Later"}
						border={"text"}
						color={"text-primary"}
						event={() => {
							history.push("/");
							setStartTour(false);
						}}
					/>

					<Buttons
						text={"Continue"}
						border={"text"}
						color={"text-primary"}
						event={() => {
							if (!user?.updated_profile) {
								handleProf();
								setStartTour(false);
							} else if (user?.updated_profile && !canCapture) {
								handlePlan();
								setStartTour(false);
							} else if (user?.updated_profile && canCapture) {
								handleCapture();
								setStartTour(false);
							}
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default TourSteps;
