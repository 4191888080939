import React, { useState, useEffect, useContext } from 'react'
import image from '../assets/images/heart-home.png'
import { Link } from 'react-router-dom'
import History from './History'
import AuthContext from './context/AuthContext'
// import { API_URL } from './config'
import Cookies from 'universal-cookie'
// import ReactApexChart from 'react-apexcharts'
import Chart from './Chart'
import HomeHistory from './HomeHistory'
import { RiErrorWarningLine } from 'react-icons/ri'
import './General.scss'
import Modal from './Modal'
import { useHistory } from 'react-router-dom'
import TelcoSub from './TelcoSub'
import doctor from 'assets/images/doctor.png'
import Buttons from './Buttons'

const Dash = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const {
		user,
		medData,
		profData,
		chartData,
		checkUserLoggedIn,
		handleInit,
		isSubscribe,
		setIsSubscribe,
		setProfile,
		canCapture,
		setIsLoading,
		profile,
		setTelcoSub,
		handleFinance,
	} = useContext(AuthContext)



	const history = useHistory()

	const [isModal, setIsModal] = useState(false)
	const [first, setFirst] = useState('')
	const [isName, setIsName] = useState(false)
	// const [telcoSub, setTelcoSub] = useState(false)

	useEffect(() => {
		checkUserLoggedIn()
		handleInit()
		handleFinance()
		setIsLoading(true)

		// if (user?.updated_profile && canCapture) {
		// 	setProfile(true)
		// } else {
		// 	setProfile(false)
		// }

		if (user?.telco_subscription === null) {
		
			setFirst(user?.first_name)
			setIsName(true)
		} else if (!user?.updated_profile && user?.telco_subscription) {
			setFirst('')
			setIsName(false)

	
		} else if (user?.updated_profile && user.telco_subscription) {
			setFirst(user?.first_name)
			setIsName(true)
		}

		setIsLoading(false)
	}, [])

	

	const handleIsSubscribe = () => {
		setIsSubscribe(false)

		if (user?.telco_subscription === null) {
			history.push('/plans/explore')
		} else {
			history.push('/plans/telco')
			setTelcoSub(true)
		}
	}

	return (
		<div className=' pt-[8rem] w-[70%] mx-auto max-md:w-[90%] pb-[5rem] max-lg:w-[90%] max-xl:w-[80%] max-md:pt-[7rem]'>
			{/* <a href="sms:+911234567890">Send a SMS</a> */}

			{isSubscribe ? (
				<Modal
					text={
						'You do not have an active subscription. Please subscribe so you can access the service.'
					}
					btn={true}
					btnType={'primary'}
					btnText={'Subscribe'}
					btnCount={1}
					color={'text-white bg-primary rounded-md px-[2rem] medium'}
					event={() => handleIsSubscribe()}
					icon=''
					px=''
					header='Please Subscribe!'
					otherEvent=''
					bg=''
					btnText2=''
					btnType2=''
					bg2=''
					color2=''
					px2=''
				/>
			) : (
				<></>
			)}

			{isModal && (
				<Modal
					text={'Please update your profile before taking a reading.'}
					btn={true}
					btnType={'primary'}
					btnText={'Update Profile'}
					btnCount={1}
					color={'text-white bg-primary rounded-md px-[2rem] medium'}
					event={() => history.push('/account/edit')}
				/>
			)}
			<div className=' max-md:flex items-center mb-[2rem] hidden'>
				{isName && (
					<div className=' regular bg-[#AEC5F1] rounded-md py-[0.5rem] px-[1rem] max-md:px-[0.5rem] max-md:py-[0.1rem] mr-[1rem] max-md:text-3xl '>
						{user?.first_name.charAt(0)}
					</div>
				)}
				<p className=' max-md:text-lg regular'>
					Hi {!user?.missing_fields ? user?.first_name : ''}, Welcome
				</p>
			</div>
			{!user?.updated_profile && (
				<Link
					to='/account/edit'
					className='fixed bg-light_blue py-[0.5rem] px-[1rem] rounded-sm top-[1rem] z-[2] flex items-center max-md:relative max-md:mb-[3rem] max-md:justify-center max-md:w-[90%] max-md:z-[0]'
				>
					<p className=' text-md mr-[1rem]'>
						Profile setup: Just a few more details
					</p>
					<RiErrorWarningLine className=' text-4xl text-primary' />
				</Link>
			)}
			<div className=' flex w-[100%] justify-between items-end mb-[2rem] max-md:w-full max-sm:flex-col max-sm:items-start max-lg:flex-col max-lg:items-start'>
				<div className=' max-lg:mb-[2rem]'>
					<img src={image} alt='' className=' mb-[1rem] max-md:w-[7rem]' />
					<p className=' mb-[1rem] max-md:text-sm'>
						Track your vital signs today
					</p>
					{!user?.updated_profile ? (
						<button
							className=' bg-primary py-[0.5rem] px-[5rem] text-white rounded-sm max-md:px-[1rem] max-md:text-sm'
							onClick={() => setIsModal(true)}
						>
							Take a reading
						</button>
					) : (
						<Link
							to={`/capture/?weight=${medData?.weight}&height=${medData?.height}&age=${profData?.age}`}
						>
							<button className=' bg-primary py-[0.5rem] px-[5rem] text-white rounded-sm max-md:px-[1rem] max-md:text-sm'>
								Take a reading
							</button>
						</Link>
					)}
				</div>

				{user?.has_chat_subscription && (
					<div className=' bg-light_blue rounded-md p-[1rem] flex items-center shadow-md justify-between'>
						<div className=' bg-white w-[8rem] h-[8rem] rounded-full p-[1rem] mr-[1rem] shadow-sm max-md:w-[5rem] max-md:h-[5rem] max-md:p-[0.5rem]'>
							<img
								src={doctor}
								alt=''
								className=' w-[5rem] mx-auto mb-[1rem] max-md:w-[7rem]'
							/>
						</div>
						<div className=''>
							<h1 className='font-bold text-xl mb-[1rem]'>
								Start a Consultation
							</h1>
							<p className=' text-sm mb-[1rem] w-[80%]'>
								Vitals Checked! Need to chat with a doctor?
							</p>
							<Link to={'/capture/results/doctor'}>
								<button className=' bg-primary py-[0.5rem] px-[2rem] text-white rounded-sm max-md:px-[1rem] max-md:text-sm'>
									Connect now
								</button>
							</Link>
						</div>
					</div>
				)}
			</div>

			<Link to={'/check-bmi'} className='mt-[3rem]'>
				<button className=' bg-primary py-[0.5rem] px-[2rem] text-white rounded-sm max-md:px-[1rem] max-md:text-sm'>
					Check BMI
				</button>
			</Link>

			{chartData?.has_data && <Chart />}

			<HomeHistory />
		</div>
	)
}

export default Dash
