import React, { useEffect } from "react";
import History from "components/History";

const HistoryPage = () => {
  return (
    <div>
      <History />
    </div>
  );
};

export default HistoryPage;
