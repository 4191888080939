import React from 'react'
import Buttons from './Buttons'

const PlanTour = ({ text, btnText1, btnText2, event1, event2, isFinish }) => {
	return (
		<div>
			<div className=' fixed top-0 right-0 z-[3] bg-black bg-opacity-40 w-[100vw] h-[100vh]'>
				<div className='rounded-md bg-white w-[20rem] text-center flex flex-col items-center p-[1rem] relative top-[22rem] left-[10rem] max-2xl:left-[10rem] max-xl:left-[10rem] max-md:left-[14rem] max-md:top-[30rem] max-sm:left-[1rem]'>
					<div className='absolute left-0 top-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-white max-md:left-3/4 max-md:top-[6.8rem] '></div>
					<p className=' text-text_gray mb-[1rem]'>{text}</p>
					<div className='flex justify-between items-center w-[70%]'>
						<Buttons
							text={btnText1}
							border={'text'}
							color={'text-primary'}
							event={event1}
						/>
						{!isFinish && (
							<Buttons
								text={btnText2}
								border={'text'}
								color={'text-primary'}
								event={event2}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default PlanTour
