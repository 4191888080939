import Discount from "components/Discount";
import React from "react";

const Discounts = () => {
  return (
    <div>
      <Discount />
    </div>
  );
};

export default Discounts;
