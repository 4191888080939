// src/Modal.js
import React from "react";

const Modal = ({ isOpen, onClose, children }) => {
	if (!isOpen) {
		return null;
	}

	return (
		<div style={styles.overlay}>
			<div style={styles.modal}>
				{children}
				<button onClick={onClose} style={styles.button}>
					Close
				</button>
			</div>
		</div>
	);
};

const styles = {
	overlay: {
		position: "fixed",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	modal: {
		backgroundColor: "#fff",
		padding: "20px",
		borderRadius: "8px",
		maxWidth: "500px",
		width: "100%",
		boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
		textAlign: "center",
	},
	button: {
		marginTop: "20px",
		padding: "10px 20px",
		backgroundColor: "#007BFF",
		color: "white",
		border: "none",
		borderRadius: "4px",
		cursor: "pointer",
	},
};

export default Modal;
