import Bmi from 'components/Bmi'
import React from 'react'

const CheckBmi = () => {
	return (
		<div>
			<Bmi />
		</div>
	)
}

export default CheckBmi
