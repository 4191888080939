import TelcoSub from 'components/TelcoSub'
import React from 'react'

const Telco = () => {
	return (
		<div>
			<TelcoSub />
		</div>
	)
}

export default Telco
