import Action from 'components/Action'
import Cards from 'components/Cards'
import Faq from 'components/Faq'
import Footer from 'components/Footer'
import Landing from 'components/Landing'
import Nav from 'components/Nav'
import React, { useEffect, useContext } from 'react'
import AuthContext from 'components/context/AuthContext'
import { useHistory } from 'react-router-dom'

const Home = () => {
	const { setProfile, user, canCapture } = useContext(AuthContext)


	return (
		<div className=' bg-red'>
			<Nav />
			<Landing />
			<Cards />
			<Action />
			<Faq />
			<Footer />
		</div>
	)
}

export default Home
