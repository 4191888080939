export const serviceMessages = [
  {
    // title: 'Get into position',
    description:
      'Please ensure your face takes up most of the camera view above',
  },
  {
    title: 'Calibrating',
    description: 'Please keep still',
  },
];

export const calculatingMessages = [
  {
    // title: 'Analyzing your physiology',
    description: 'This may take up to 2mins',
  },
  {
    // title: 'Extracting your cardiac rhythm',
    description: 'Make sure your face is well lit',
  },
  {
    // title: 'Analyzing your physiology',
    description: 'This may take up to 2mins',
  },
  {
    // title: 'Hang tight',
    description: 'The circles below will indicate when we have values',
  },
  {
    // title: 'Expanding biometric search',
    description: 'We\'re fetching more physiological metrics',
  },
];

export const intervalDelay = 5000;
