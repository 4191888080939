import React, { useContext, useEffect, useState } from "react";
import AuthContext from "./context/AuthContext";
import "./General.scss";
// import { API_URL } from "./config";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import Modal from "./Modal";
import Buttons from "./Buttons";
import BmiModal from "./BmiModal";
import RangeSlider from "./RangeSlider";

const Bmi = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const { user, setIsLoading, medData, profData } = useContext(AuthContext);

	

	const [weight, setWeight] = useState(medData?.weight);
	const [height, setHeight] = useState(medData?.height * 0.01);
	const [modal, setModal] = useState(false);
	const [bmiData, setBmiData] = useState();
	const [color, setColor] = useState("");
	const [history, setHistory] = useState();

	const cookies = new Cookies();

	useEffect(() => {
		setWeight(medData?.weight);
		handleFetchBmi();
		setHeight(medData?.height);
	}, []);

	const handleFetchBmi = async () => {
		const res = await fetch(`${API_URL}/user/bmi`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
			},
		});

		const data = await res.json();

		setHistory(data.bmis);

		
	};

	const handleBMI = async (e) => {
		e.preventDefault();

		setIsLoading(true);

		const res = await fetch(`${API_URL}/user/bmi`, {
			method: "POST",
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
			},
			body: JSON.stringify({
				height: height / 100,
				weight,
			}),
		});

		const data = await res.json();

		

		if (res.ok) {
			setModal(true);
			setBmiData(data?.bmi);
			setColor(data.bmi.color);
		} else {
			if (data.height && data.weight) {
				toast.error("All fields are required!", {
					duration: 6000,
				});
			} else if (data.height) {
				toast.error(data.height[0], {
					duration: 6000,
				});
			} else if (data.weight) {
				toast.error(data.weight[0], {
					duration: 6000,
				});
			}
		}

		setIsLoading(false);
	};

	return (
		<div className='flex relative top-[5rem] w-[70vw] mx-auto justify-between max-lg:flex-col max-lg:justify-center max-lg:items-center max-md:w-[90vw]'>
			{modal && (
				<BmiModal setModal={setModal} bmiData={bmiData} color={color} />
			)}
			<div className=' w-[20vw] mx-auto flex flex-col max-md:w-[80vw] max-lg:w-[40vw]'>
				<h1 className=' medium text-2xl mb-[2rem]'>Check BMI</h1>

				<form action='' onSubmit={handleBMI}>
					<label
						htmlFor='height'
						className=' text-sm text-text_gray mb-[0.5rem]'>
						Height (cm)
					</label>
					<input
						type='number'
						name='height'
						id='height'
						value={height}
						className=' border border-bluee bg-input_bg rounded-md p-[0.5rem] mb-[2rem] w-full text-lg'
						onChange={(e) => setHeight(e.target.value)}
						placeholder='Height in centimeters'
					/>

					<label
						htmlFor='weight'
						className=' text-sm text-text_gray mb-[0.5rem]'>
						Weight (kg)
					</label>
					<input
						type='number'
						name='weight'
						id='weight'
						value={weight}
						className=' border border-bluee bg-input_bg rounded-md p-[0.5rem] mb-[2rem] w-full text-lg'
						onChange={(e) => setWeight(e.target.value)}
						placeholder='Weight in kilograms'
					/>

					<button className='bg-primary text-white rounded-md py-[0.5rem] px-[5rem] max-md:w-[80vw] max-md:px-[1rem]'>
						Calculate BMI
					</button>
				</form>
			</div>

			<div className=' w-[20rem] border-l border-gray-300 pl-[2rem] max-lg:border-none max-lg:w-[40vw] max-lg:pl-[0rem] max-md:w-[80vw] mb-[100px]'>
				<h1 className=' medium text-2xl mb-[2rem] max-lg:mb-[1rem] max-lg:mt-[2rem]'>
					BMI History
				</h1>
				{history?.map((hist) => (
					<div className=' border-b border-gray-300 mb-[1rem]' key={hist.id}>
						<p className=' bg-bg_green text-white p-[0.5rem] rounded-md'>
							{hist?.date}
						</p>
						<p className=' bold mb-[0.2rem] text-lg'>
							BMI Score: <span>{hist?.bmi}</span>
						</p>
						<p className={`bold mb-[0.5rem]`}>
							Category:{" "}
							<span
								style={{ color: hist?.color }}
								className={`text-[${hist?.color}] mb-[0.5rem]`}>
								{hist?.category}
							</span>
						</p>
						<RangeSlider data={hist} />
						<p className='text-sm my-[1rem]'>{hist?.copy}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default Bmi;
