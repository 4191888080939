import React, { useState, useEffect, useContext } from "react";
import image from "assets/images/sad.png";
import question from "assets/images/question.png";
import Buttons from "./Buttons";
import "./General.scss";
// import { API_URL } from "./config";
import Cookies from "universal-cookie";
import AuthContext from "./context/AuthContext";
import Modal from "./Modal";
import { toast } from "react-hot-toast";

const CancelSub = ({ expiry, isModal, setIsModal, subscription, typeSub }) => {
	const API_URL = process.env.REACT_APP_API_URL;
	const [isOne, setIsOne] = useState(true);
	const [isTwo, setIsTwo] = useState(false);
	const [modal, setModal] = useState(false);
	const [username, setUsername] = useState("");
	const [reason, setReason] = useState("");
	const [comment, setComment] = useState("");
	const [list, setList] = useState();
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const { setIsLoading, setMessage, message } = useContext(AuthContext);

	const cookies = new Cookies();

	

	useEffect(() => {
		const handleCancel = async () => {
			const res = await fetch(
				`${API_URL}/finance/subscription/cancel-reason-list`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
					},
				},
			);

			const data = await res.json();

			

			

			setList(data);

			// let allList = [];

			// reasons.forEach((key, index) => {
			// 	allList.push(data[key]);
			// });

			// setList(allList);
		};

		handleCancel();
	}, []);

	const handleSecond = () => {
		setIsOne(false);

		setIsTwo(true);
	};

	const cancelEvent = async () => {
		setIsLoading(true);
		const res = await fetch(
			`${API_URL}/finance/subscription/individual/cancel`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
				},
				body: JSON.stringify({
					username,
					subscription,
					reason,
					comment,
				}),
			},
		);

		const data = await res.json();

		

		if (res.ok) {
			// setModal(true);

			setSuccess(true);

			setIsTwo(false);

			// setIsModal(false);
			setMessage(data.message);
		} else {
			// setError(true)
			// setIsTwo(false)

			// setIsModal(true);
			if (data.reason && data.username) {
				toast.error("Username & why you are cancelling is required!", {
					duration: 6000,
				});
			} else if (data.reason) {
				toast.error(data.reason, {
					duration: 6000,
				});
			} else if (data.username) {
				toast.error(data.username, {
					duration: 6000,
				});
			} else {
				toast.error(data.message, {
					duration: 6000,
				});
			}
		}

		setIsLoading(false);
	};

	const cancelBundleEvent = async () => {
		setIsLoading(true);
		const res = await fetch(
			`${API_URL}/finance/subscription/bundled-subscription/cancel`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${cookies.get("vsm_authorization")}`,
				},
				body: JSON.stringify({
					username,
					subscription,
					reason,
					comment,
				}),
			},
		);
		const data = await res.json();
		
		if (res.ok) {
			// setModal(true);
			setSuccess(true);
			setIsTwo(false);
			// setIsModal(false);
			setMessage(data.message);
		} else {
			// setError(true)
			// setIsTwo(false)
			// setIsModal(true);
			if (data.reason && data.username) {
				toast.error("Username & why you are cancelling is required!", {
					duration: 6000,
				});
			} else if (data.reason) {
				toast.error(data.reason, {
					duration: 6000,
				});
			} else if (data.username) {
				toast.error(data.username, {
					duration: 6000,
				});
			} else {
				toast.error(data.message, {
					duration: 6000,
				});
			}
		}
		setIsLoading(false);
	};

	const handleModal = () => {
		setError(false);
		setSuccess(false);
		setModal(false);

		setIsModal(false);

		window.location.reload();
	};

	return (
		<div className=' fixed z-[5] w-[100vw] h-[100vh] bg-black bg-opacity-75 top-0 left-0 grid justify-items-center items-center'>
			{isOne && (
				<div className=' bg-white rounded-md flex flex-col items-center p-[2rem] w-[60vw] mx-auto max-md:w-[90vw] max-md:px-[1rem] max-md:pb-[5rem] max-md:h-[95vh] '>
					<h1 className=' text-xl mb-[1rem] text-center'>
						You are About to Cancel Your Subscription Renewal
					</h1>
					<p className=' text-center mb-[1rem] max-md:text-sm'>
						Are you sure you want to cancel your subscription? You will no
						longer have access to the VSM Premium features after {expiry}.
					</p>
					<img
						src={image}
						alt=''
						className=' w-[7rem] mb-[1rem] max-md:w-[4rem]'
					/>

					<div className=' bg-light_blue shadow-md p-[1rem] mb-[2rem]'>
						<div className=' flex mb-[1rem] '>
							<img src={question} alt='' className=' mr-[1rem] items-center' />
							<p className=' medium text-lg max-md:text-md'>
								Have Issues or Questions?
							</p>
						</div>
						<p className='mb-[1rem] max-md:text-sm'>
							Our support team can help you with any problem you may be facing.
						</p>

						<a href='https://wa.me/2349115357296?text=Hi%2C%20I%20need%20help%20with%20my%20VSM%20Subscription'>
							<Buttons
								text={"Chat with us"}
								bg={"bg-primary text-white px-[1rem]"}
							/>
						</a>
					</div>
					<div className=' flex w-full max-md:flex-col'>
						<Buttons
							text={"Continue to cancel"}
							bg={
								" border border-2 text-primary border-primary px-[4rem] w-full mr-[2rem] max-md:mb-[1rem]"
							}
							event={() => handleSecond()}
						/>
						<Buttons
							text={"Close"}
							bg={
								" border border-2 text-white border-primary bg-primary px-[4rem] w-full"
							}
							event={() => setIsModal(false)}
						/>
					</div>
				</div>
			)}

			{isTwo && (
				<div className=' bg-white rounded-md p-[2rem] w-[60vw] mx-auto max-md:px-[1] max-md:w-[90vw]'>
					<p className=' text-center mb-[1rem] medium w-[80%] mx-auto max-md:w-[90%]'>
						We are eager to improve our product and would appreciate your
						feedback
					</p>

					<div className=' w-[80%] mx-auto max-md:w-[90%]'>
						<label
							htmlFor='username'
							className=' text-sm text-text_gray mb-[0.5rem]'>
							Username (Email/Phone Number)
						</label>
						<input
							type='text'
							name='username'
							id='username'
							value={username}
							className=' border border-bluee bg-input_bg rounded-md p-[0.5rem] mb-[2rem] w-full text-lg mt-[0.5rem] max-md:mb-[1rem]'
							onChange={(e) => setUsername(e.target.value)}
						/>

						<label
							htmlFor='reason'
							className=' text-sm text-text_gray mb-[0.5rem]'>
							Why are you cancelling?
						</label>
						<select
							name='reason'
							id='reason'
							className=' border border-bluee bg-input_bg rounded-md p-[0.5rem] mb-[2rem] w-full text-lg mt-[0.5rem] max-md:mb-[1rem]'
							onChange={(e) => setReason(e.target.value)}
							value={reason}>
							<option value='' disabled selected hidden>
								Select Reason
							</option>
							{list?.reasons.map((e) => (
								<option value={e.id} key={e.id}>
									{e.label}
								</option>
							))}
						</select>

						<label
							htmlFor='comment'
							className=' text-sm text-text_gray mb-[0.5rem]'>
							Any additional comment
						</label>
						<textarea
							name='comment'
							value={comment}
							onChange={(e) => setComment(e.target.value)}
							id='comment'
							cols='30'
							rows='5'
							className=' border border-bluee bg-input_bg rounded-md p-[0.5rem] mb-[2rem] w-full text-lg mt-[0.5rem] max-md:mb-[1rem] max-md:h-[4rem]'></textarea>

						<div className=' flex w-full max-md:flex-col'>
							<Buttons
								text={"Keep my plan"}
								bg={
									" border border-2 text-primary border-primary px-[4rem] w-full mr-[2rem] max-md:mb-[1rem]"
								}
								event={() => setIsModal(false)}
							/>
							<Buttons
								text={"Cancel Subscription"}
								bg={
									" border border-2 text-white border-primary bg-primary px-[4rem] w-full"
								}
								event={() => {
									if (typeSub?.split(" ")[1] === "Bundle") {
										cancelBundleEvent();
									} else if (typeSub?.split(" ")[0] === "Individual") {
										cancelEvent(false);
									}
								}}
							/>
						</div>
					</div>
				</div>
			)}

			{error && (
				<Modal
					text={message}
					// header={"Subscription renewal cancelled!"}
					btn={true}
					btnType={"text"}
					btnText={"Ok"}
					btnCount={1}
					color={"text-primary medium"}
					event={() => handleModal()}
				/>
			)}

			{success && (
				<Modal
					text={message}
					// header={"Subscription renewal cancelled!"}
					btn={true}
					btnType={"text"}
					btnText={"Ok"}
					btnCount={1}
					color={"text-primary medium"}
					event={() => handleModal()}
				/>
			)}
		</div>
	);
};

export default CancelSub;
