import ProfileEdit from "components/ProfileEdit";
import React from "react";

const Edit = () => {
  return (
    <div>
      <ProfileEdit />
    </div>
  );
};

export default Edit;
