import Buttons from './Buttons'
import RangeSlider from './RangeSlider';

function BmiModal(props) {
	return (
		<div className=' fixed top-0 right-0 z-[10] bg-black bg-opacity-40 w-[100vw] h-[100vh] flex items-center justify-center'>
			<div className=' rounded-md bg-white w-[20rem] flex flex-col p-[1rem]'>
				<p className=' bold mb-[0.5em] text-2xl'>
					BMI Score: <span>{props.bmiData?.bmi}</span>
				</p>
				<p className={`bold mb-[0.5rem]`}>
					Category:{" "}
					<span
						style={{ color: props?.color }}
						className={`text-[${props.color}] mb-[0.5rem]`}>
						{props.bmiData?.category}
					</span>
				</p>
				<RangeSlider data={props?.bmiData} />
				<p className='text-sm my-[1rem]'>{props.bmiData?.copy}</p>
				<div className=''>
					<Buttons
						text={"Close"}
						border={"text"}
						bg={"none"}
						color={"text-primary"}
						px={"bold"}
						event={() => props.setModal(false)}
					/>
				</div>
			</div>
		</div>
	);
}

export default BmiModal
