// https://fluentsite.z22.web.core.windows.net/quick-start
import React, { useContext, useEffect, useState } from "react";
import { Loader } from "@fluentui/react-northstar";
import { BrowserRouter, Redirect, Route, useHistory } from "react-router-dom";
import { useTeamsFx } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "./Context";
import { TeamsTheme } from "@fluentui/react-teams/lib/cjs/themes";
import { Provider } from "@fluentui/react-teams";
import {
	Capture,
	NotSupported,
	BadConditions,
	Results,
	// ResultsPage,
} from "tabs/CaptureTab/Views";
import "./App.scss";
import Home from "pages/Home";
import Nav from "components/Nav";
import Register from "pages/Register";
import { AuthProvider } from "components/context/AuthContext";
import Login from "pages/Login";
import { createBrowserHistory } from "history";
import ForgotPassword from "pages/ForgotPassword";
import { Toaster, toast } from "react-hot-toast";
import UserNav from "components/UserNav";
import Dashboard from "pages/Dashboard";
import AuthContext from "components/context/AuthContext";
import Account from "pages/Account";
import Edit from "pages/Edit";
import ChatDoc from "pages/ChatDoc";
import HistoryPage from "pages/HistoryPage";
import Delete from "pages/Delete";
import Plans from "pages/Plans";
import PlansNav from "components/PlansNav";
import Explore from "pages/Explore";
import Discounts from "pages/Discounts";
import SubHistory from "pages/SubHistory";
import Individual from "pages/Individual";
import Payment from "pages/Payment";
import Corperate from "pages/Corperate";
import Onboard from "pages/Onboard";
import Enrollee from "pages/Enrollee";
import BankDiscount from "pages/BankDiscount";
import { useServiceWorker } from "useServiceWorker";
import Buttons from "components/Buttons";
import LoaderComponent from "components/LoaderComponent";
import Learn from "pages/Learn";
import { requestForToken, onMessageListener } from "firebaseApp";
import ResultDownload from "pages/ResultDownload";
import Notification from "Notification";
import UserTour from "components/UserTour";
import RemindMe from "components/RemindMe";
import Telco from "pages/Telco";
import ReactGA from "react-ga";
// import PwaInstallPopupIOS from 'react-pwa-install-ios'
import { AddToHomeScreen } from "react-pwa-add-to-homescreen";
import PWAPrompt from "react-ios-pwa-prompt";
import Bundle from "pages/Bundle";
import CheckBmi from "pages/CheckBmi";
import MainAccountDeletion from "pages/MainAccountDeletion";
// import AddToHomescreen from 'react-add-to-homescreen'

// import ResultsPage from "tabs/CaptureTab/Views/Results/ResultsPage";
// import { AnimatePresence } from "framer-motion";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
	const { loading, theme, themeString, teamsfx } = useTeamsFx();

	const platform = require("platform");

	const [isAllow, setIsAllow] = useState<boolean>(false);
	const [supportsPWA, setSupportsPWA] = useState<boolean>(false);
	const [promptInstall, setPromptInstall] = useState<any>(null);
	const [isPrompt, setIsPrompt] = useState<boolean>(false);

	const hist = createBrowserHistory();

	const history = useHistory();

	const TRACKING_ID = "G-Y85VX0MCVX";
	// const TRACKING_ID = "G-CYSB64QNXW";

	ReactGA.initialize(TRACKING_ID);

	ReactGA.pageview(document.location.pathname);
	ReactGA.send({
		hitType: "Page View",
		page: window.location.pathname,
	});

	useEffect(() => {
		if (
			(window.navigator as any).standalone ||
			window.matchMedia("(display-mode: standalone)").matches
		) {
			ReactGA.event({
				category: "PWA",
				action: "Installed",
				label: "StandaloneMode",
			});
		} else {
			return;
		}
	}, []);

	useEffect(() => {
		const handler = (e) => {
			e.preventDefault();

			setSupportsPWA(true);
			setPromptInstall(e);
			setIsPrompt(true);
		};
		window.addEventListener("beforeinstallprompt", handler);

		return () => window.removeEventListener("transitionend", handler);
	}, []);

	const { waitingWorker, showReload, reloadPage } = useServiceWorker();
	// decides when to show the toast
	useEffect(() => {
		console.log("this work")

		if (showReload && waitingWorker) {
			toast((t) => (
				<span className=' p-[1rem]'>
					A new version of the app is available
					{/* <button onClick={() => reloadPage()}>REFRESH</button> */}
					<div className=' w-full flex justify-center mt-[1rem]'>
						<Buttons
							bg={"bg-primary"}
							border={"border-2 border-primary"}
							color={"text-white"}
							text={"Refresh"}
							px={"px-[2rem]"}
							resonsive={false}
							event={() => reloadPage()}
						/>
					</div>
				</span>
			));
		}
	}, [waitingWorker, showReload, reloadPage]);

	const {
		user,
		logout,
		isLoading,
		isPlansNav,
		canCapture,
		isRemind,
		setisRemind,
		profile,
		hasCaptured,
	} = useContext(AuthContext);

	const pathname = window.location.pathname;

	// const handlePrompt = (e: any) => {
	// 	toast.dismiss(e.id)

	// 	setIsPrompt(false)
	// }

	useEffect(() => {
		const handlePrompt = () => {
			if (platform.os.family === "iOS") {
				return <AddToHomeScreen delayNotify={0} skipFirstVisit={false} />;
			}
		};

		handlePrompt();

		// const onClick = (evt) => {
		// 	evt.preventDefault()
		// 	if (!promptInstall) {
		// 		return
		// 	}
		// 	promptInstall.prompt()

		// 	console.log('working')
		// }

		// console.log(isPrompt)

		// platform.os.family === 'iOS' &&
		// 	isPrompt &&
		// 	toast((t) => (
		// 		<span className=' p-[1rem]'>
		// 			<div className=' w-full flex justify-center mt-[1rem]'>
		// 				<div className='' onClick={() => handlePrompt(t)}>
		// 					<Buttons
		// 						bg={'bg-primary'}
		// 						border={'border-2 border-primary'}
		// 						color={'text-white'}
		// 						text={'Install App'}
		// 						px={'px-[1rem]'}
		// 						resonsive={false}
		// 						event={onClick}
		// 					/>
		// 				</div>
		// 			</div>
		// 		</span>
		// 	))
	}, [platform.os.family]);

	// if (!supportsPWA) {
	// 	return null
	// }

	const handleAddToHomescreenClick = () => {
		alert(`
			1. Open Share menu
			2. Tap on "Add to Home Screen" button`);
	};

	return (
		<div className='font-face'>
			{platform.os.family === "iOS" && (
				<PWAPrompt
					copyTitle='SignalADoc VSM Web App'
					copyBody='This application has app functionality. Add it to your home screen to use it in fullscreen.'
					promptOnVisit={1}
					timesToShow={1000}
					copyClosePrompt='Close'
					permanentlyHideOnDismiss={false}
				/>
			)}

			{user && !user?.updated_profile && !canCapture && !profile ? (
				<UserTour />
			) : (
				<></>
			)}

			{user && !user?.updated_profile && !profile ? <UserTour /> : <></>}

			{user &&
			user?.updated_profile &&
			!canCapture &&
			!pathname.includes("/plans/") ? (
				<UserTour />
			) : (
				<></>
			)}

			{user &&
			user?.updated_profile &&
			canCapture &&
			!hasCaptured &&
			!pathname.includes("/capture") ? (
				<UserTour />
			) : (
				<></>
			)}

			{user &&
			user?.updated_profile &&
			canCapture &&
			pathname.includes("/plans/") ? (
				<UserTour />
			) : (
				<></>
			)}

			{/* {user && !profile && !user?.missing_fields && <UserTour />} */}
			<Toaster position='top-center' reverseOrder={false} />
			{isLoading && <LoaderComponent />}
			{user && pathname !== "/" ? <UserNav /> : <></>}
			{/* {pathname === "/plans" ||
				pathname === "/plans/explore" ||
				pathname === "/plans/history" ||
				(pathname === "/plans/discounts" && <PlansNav />)} */}
			{loading ? (
				<Loader style={{ margin: 100 }} />
			) : (
				<>
					{user && (
						<>
							<Route
								path={"/capture"}
								render={({ match: { url } }) => (
									<>
										<Route exact path={`${url}/`} component={Capture} />
										<Route
											exact
											path={`${url}/not-supported`}
											component={NotSupported}
										/>
										<Route
											exact
											path={`${url}/bad-conditions`}
											component={BadConditions}
										/>
										<Route exact path={`${url}/results`} component={Results} />
										<Route
											exact
											path={`${url}/results/doctor`}
											component={ChatDoc}
										/>
									</>
								)}
							/>
							<Route exact path={"/history"} component={HistoryPage} />
							<Route exact path={"/download"} component={ResultDownload} />

							<Notification />

							<Route path='/home' component={Dashboard} />
							<Route path='/check-bmi' component={CheckBmi} />
							<Route
								path={"/plans"}
								render={({ match: { url } }) => (
									<>
										<>
											{isPlansNav && <PlansNav />}
											<Route exact path={`${url}/`} component={Plans} />
											<Route
												exact
												path={`${url}/explore`}
												component={Explore}
											/>
											<Route
												exact
												path={`${url}/discounts`}
												component={Discounts}
											/>
											<Route
												exact
												path={`${url}/history`}
												component={SubHistory}
											/>
										</>
										<Route
											exact
											path={"/plans/individual"}
											component={Individual}
										/>
										<Route exact path={"/plans/bundle"} component={Bundle} />
										<Route exact path={"/plans/telco"} component={Telco} />
										<Route
											exact
											path={"/plans/payment-summary"}
											component={Payment}
										/>
										<Route
											exact
											path={"/plans/enrollee"}
											component={Enrollee}
										/>
										<Route
											exact
											path={"/plans/discounts/:slug"}
											component={BankDiscount}
										/>
									</>
								)}
							/>

							<Route
								path='/account'
								render={({ match: { url } }) => (
									<>
										<Route exact path={`${url}/`} component={Account} />
										<Route path={`${url}/edit`} component={Edit} />
										<Route path={`${url}/delete-account`} component={Delete} />
									</>
								)}
							/>
						</>
					)}
				</>
			)}

			<Route exact path={"/"} component={Home} />
			<Route exact path={"/learn"} component={Learn} />
			<Route exact path={"/plans/corporate"} component={Corperate} />
			<Route exact path={"/plans/onboard"} component={Onboard} />
			{/* <Route exact path={`/capture/results`} component={ResultsPage} /> */}
			<>
				<Route exact path={"/login"} component={Login} />
				<Route exact path={"/register"} component={Register} />
				<Route exact path={"/forgot-password"} component={ForgotPassword} />
			</>
			<Route path='/account-deletion' component={MainAccountDeletion} />
			{/* </Provider> */}
			{/* </TeamsFxContext.Provider> */}
		</div>
	);
}
