import React, { useEffect, useContext } from 'react'
import Unavailable from './Unavailable'
import image from 'assets/images/discount.png'
import DiscountCard from './DiscountCard'
import { Link } from 'react-router-dom'
import AuthContext from './context/AuthContext'

const Discount = () => {
	const { isLoading, setIsPlansNav, discount } = useContext(AuthContext)

	useEffect(() => {
		setIsPlansNav(true)
	}, [])

	return (
		<div className=' w-[60vw] mx-auto max-md:w-[90vw]'>
			<p className='text-2xl medium fixed top-[2rem] z-[4] max-md:left-[6rem] max-md:z-[0]'>
				Discounts
			</p>

			<p className=' text-xl medium pt-[2rem] text-center mb-[2rem] max-md:text-left'>
				Choose Your Bank. Get a Discount. Pay with your bank card.{' '}
			</p>
			<div className=' max-md:w-[90vw] grid grid-cols-4 max-md:grid-cols-3 max-sm:grid-cols-2'>
				{discount && !isLoading ? (
					discount.map((e) => (
						<Link
							to={`/plans/discounts/${e.id}`}
							className='flex w-[50vw] mx-auto max-md:w-[10rem] max-md:m-[0.5rem]'
							key={e.id}
						>
							<DiscountCard
								logo={e.logo_thumb}
								text={`Get ${e.discount} discount`}
							/>
						</Link>
					))
				) : (
					<div className=''>
						{!isLoading && (
							<Unavailable
								text={
									'There are no discounts available. Check back another time!'
								}
								icon={image}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

export default Discount
