import React, { useState, useEffect, useContext } from 'react'
import AuthContext from './context/AuthContext'
// import { API_URL } from './config'
import Cookies from 'universal-cookie'
import { BsChevronRight, BsInfoCircleFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import heartResult from 'assets/images/heart-hist.png'
import respRateResult from 'assets/images/resp-hist.png'
import oxygenResult from 'assets/images/oxygen-hist.png'
import stressResult from 'assets/images/stress-hist.png'
import bpResult from 'assets/images/bp-hist.png'
import './General.scss'
import { useHistory } from 'react-router-dom'

const History = () => {
	const API_URL = process.env.REACT_APP_API_URL;
	const cookies = new Cookies()
	const [histData, setHistData] = useState(null)
	const [modal, setModal] = useState(false)
	const [name, setName] = useState('')
	const [note, setNote] = useState('')
	const [link, setLink] = useState('')

	const { setIsLoading } = useContext(AuthContext)

	const history = useHistory()

	useEffect(() => {
		const handleHistory = async () => {
			setIsLoading(true)
			const res = await fetch(`${API_URL}/vital-sign/history`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${cookies.get('vsm_authorization')}`,
				},
			})

			const data = await res.json()

			setHistData(data)

			setIsLoading(false)
		}

		handleHistory()
	}, [])

	const handleModal = (name, description, read) => {
		setModal(!modal)

		setName(name)
		setNote(description)
		setLink(read)
	}

	const handleSlug = (e) => {
		history.push('/capture/results', {
			type: 'history',
			e,
		})
	}

	return (
		<div className='py-[7rem] relative w-[70%] mx-auto max-md:w-[90%] max-2xl:ml-[15rem] max-md:mx-auto'>
			<h1 className='medium top-0 py-[1rem] fixed z-[1] bg-white w-[100vw] text-2xl mx-auto max-md:pl-[6rem] max-md:w-[100vw] max-md:bg-white max-md:h-[5rem] max-md:left-[0rem] max-md:top-0 max-md:pt-[2rem] max-md:z-[0] max-md:text-xl'>
				Reading Result History
			</h1>

			{histData?.data.length === 0 && (
				<h2 className=' mt-[3rem]'>No reading taken</h2>
			)}

			{histData?.data.map((e) => (
				<div className=' mb-[3rem]' key={e.id}>
					<div
						className='msg-block cursor-pointer'
						onClick={() => handleSlug(e)}
					>
						<div className='flex justify-between w-[40rem] max-lg:w-[30rem] max-md:w-[100%] max-md:flex-col'>
							<p className=' mr-[3rem]'>Vital Signs</p>
							<p className=' ml-[3rem] max-md:ml-[0rem]'>{e.date_time}</p>
						</div>
					</div>

					{modal && (
						<div className=' modal fixed h-[100vh] w-[100vw] left-0 top-0 grid items-center z-[5]'>
							<div className=' modal-card bg-white p-[1rem] w-[20rem] mx-auto'>
								<h1 className=' text-xl mb-[1rem]'>{name}</h1>
								<p className=' text-sm'>{note}</p>

								<div className=' text-primary mt-[1rem] flex justify-between items-center text-sm'>
									<p
										className=' cursor-pointer'
										onClick={() => setModal(!modal)}
									>
										Close
									</p>
									<a href={link}>Read More</a>
								</div>
							</div>
						</div>
					)}

					<div className=' grid grid-cols-2 max-md:grid-cols-1 max-lg:grid-cols-1'>
						<div className='item'>
							<div className='title'>
								<div
									className={` ${
										e.blood_pressure_indicator === 'warning' && 'warning'
									} ${e.blood_pressure_indicator === 'danger' && 'danger'} ${
										e.blood_pressure_indicator === 'ok' && 'normal'
									} icon`}
								>
									<img src={bpResult} alt='icon-result' className=' w-[2rem]' />
								</div>
							</div>

							<div className=' text-center'>
								<div className='name'>Blood Pressure</div>
								<div
									className={` ${
										e.blood_pressure_indicator === 'warning' && 'warn-text'
									} ${e.blood_pressure_indicator === 'danger' && 'high-text'} ${
										e.blood_pressure_indicator === 'ok' && 'normal-text'
									} value regular`}
								>
									{e.blood_pressure_display}
									{/* <span className="sign">{item.sign}</span> */}
								</div>
								{/* <div className="no-value"></div> */}
							</div>

							<BsInfoCircleFill
								color='#AEC5F1'
								fontSize={30}
								className=' text-[#AEC5F1] text-xl cursor-pointer'
								onClick={() =>
									handleModal(
										'Blood Pressure',
										'The pressure of circulating blood on the walls of blood vessels.',
										'https://signaladoc.com/vitalsignsmonitoring/blood-pressure'
									)
								}
							/>
						</div>
						<div className='item'>
							<div className='title'>
								<div
									className={` ${
										e.beats_per_minute_indicator === 'warning' && 'warning'
									} ${e.beats_per_minute_indicator === 'danger' && 'danger'} ${
										e.beats_per_minute_indicator === 'ok' && 'normal'
									} icon`}
								>
									<img
										src={heartResult}
										alt='icon-result'
										className=' w-[2rem]'
									/>
								</div>
							</div>

							<div className=' text-center'>
								<div className='name'>Heart Rate</div>
								<div
									className={` ${
										e.beats_per_minute_indicator === 'warning' && 'warn-text'
									} ${
										e.beats_per_minute_indicator === 'danger' && 'high-text'
									} ${
										e.beats_per_minute_indicator === 'ok' && 'normal-text'
									} value regular`}
								>
									{e.beats_per_minute_display}
									{/* <span className="sign">{item.sign}</span> */}
								</div>
								{/* <div className="no-value"></div> */}
							</div>

							<BsInfoCircleFill
								color='#AEC5F1'
								fontSize={30}
								className=' text-[#AEC5F1] text-xl cursor-pointer'
								onClick={() =>
									handleModal(
										'Heart Rate',
										'Also known as pulse, this the number of times a person’s heart beats per minute.',
										'https://signaladoc.com/vitalsignsmonitoring/heart-rate'
									)
								}
							/>
						</div>

						<div className='item'>
							<div className='title'>
								<div
									className={` ${
										e.stress_status_indicator === 'warning' && 'warning'
									} ${e.stress_status_indicator === 'danger' && 'danger'} ${
										e.stress_status_indicator === 'ok' && 'normal'
									} icon`}
								>
									<img
										src={stressResult}
										alt='icon-result'
										className=' w-[2rem]'
									/>
								</div>
							</div>

							<div className=' text-center'>
								<div className='name'>Stress Level</div>
								<div
									className={` ${
										e.stress_status_indicator === 'warning' && 'warn-text'
									} ${e.stress_status_indicator === 'danger' && 'high-text'} ${
										e.stress_status_indicator === 'ok' && 'normal-text'
									} value regular`}
								>
									{e.stress_status_display}
									{/* <span className="sign">{item.sign}</span> */}
								</div>
								{/* <div className="no-value"></div> */}
							</div>

							<BsInfoCircleFill
								color='#AEC5F1'
								fontSize={30}
								className=' text-[#AEC5F1] text-xl cursor-pointer'
								onClick={() =>
									handleModal(
										'Stress Level',
										'Based on Baevsky’s and US/European Index level measurements.',
										'https://signaladoc.com/vitalsignsmonitoring/stress'
									)
								}
							/>
						</div>

						<div className='item'>
							<div className='title'>
								<div
									className={` ${
										e.oxygen_indicator === 'warning' && 'warning'
									} ${e.oxygen_indicator === 'danger' && 'danger'} ${
										e.oxygen_indicator === 'ok' && 'normal'
									} icon`}
								>
									<img
										src={oxygenResult}
										alt='icon-result'
										className=' w-[2rem]'
									/>
								</div>
							</div>

							<div className=' text-center'>
								<div className='name'>Oxygen Saturation</div>
								<div
									className={` ${
										e.oxygen_indicator === 'warning' && 'warn-text'
									} ${e.oxygen_indicator === 'danger' && 'high-text'} ${
										e.oxygen_indicator === 'ok' && 'normal-text'
									} value regular`}
								>
									{e.oxygen_display}
									{/* <span className="sign">{item.sign}</span> */}
								</div>
								{/* <div className="no-value"></div> */}
							</div>

							<BsInfoCircleFill
								color='#AEC5F1'
								fontSize={30}
								className=' text-[#AEC5F1] text-xl cursor-pointer'
								onClick={() =>
									handleModal(
										'Oxygen Saturation',
										'The percentage of oxyhemoglobin (oxygen bound hemoglobin) in the blood.',
										'https://signaladoc.com/vitalsignsmonitoring/oxygen-saturation'
									)
								}
							/>
						</div>
						<div className='item'>
							<div className='title'>
								<div
									className={` ${
										e.respiration_rate_indicator === 'warning' && 'warning'
									} ${e.respiration_rate_indicator === 'danger' && 'danger'} ${
										e.respiration_rate_indicator === 'ok' && 'normal'
									} icon`}
								>
									<img
										src={respRateResult}
										alt='icon-result'
										className=' w-[2rem]'
									/>
								</div>
							</div>

							<div className=' text-center'>
								<div className='name'>Respiratory Rate</div>
								<div
									className={` ${
										e.respiration_rate_indicator === 'warning' && 'warn-text'
									} ${
										e.respiration_rate_indicator === 'danger' && 'high-text'
									} ${
										e.respiration_rate_indicator === 'ok' && 'normal-text'
									} value regular`}
								>
									{e.respiration_rate_display}
									{/* <span className="sign">{item.sign}</span> */}
								</div>
								{/* <div className="no-value"></div> */}
							</div>

							<BsInfoCircleFill
								color='#AEC5F1'
								fontSize={30}
								className=' text-[#AEC5F1] text-xl cursor-pointer'
								onClick={() =>
									handleModal(
										'Respiratory Rate',
										'A person’s respiratory rate is the number of breaths they take per minute.',
										'https://signaladoc.com/vitalsignsmonitoring/respiratory-rate'
									)
								}
							/>
						</div>
					</div>
				</div>
			))}
		</div>
	)
}

export default History
